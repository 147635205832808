import Container from "./styled";
import FormSection from "../../FormSection";
import { BiSearch, BiFile } from "react-icons/bi";
import PolizaForm from "../../Forms/PolizaForm";
import { useWizardSiniestros } from "../../../context/WizardSiniestrosProvider";
import { useState } from "react";
import { useForm } from "../../../hooks/useForm";
import axios from "axios";
import { validarSiniestroReglas } from "../../../utils/formRules";
import url from "../../../utils/url";
import Poliza from "../../Poliza";
import { useScrollTop } from "../../../hooks/useScrollTop";

const PolizaStep = () => {
  useScrollTop()
  const { handlePoliza, nextStep } = useWizardSiniestros();
  const [loading, setLoading] = useState(false);
  const [polizas, setPolizas] = useState({
    polizas: [],
    message: "",
    dni: null,
    poliza: null,
  });
  const { inputValues, handleChange, formErrors, handleValidation } = useForm(
    handleSubmit,
    validarSiniestroReglas,
    { dni: "", poliza: "" }
  );

  async function handleSubmit() {
    setPolizas({});
    setLoading(true);
    const polizaQuery = inputValues.poliza
      ? `&poliza=${parseInt(inputValues.poliza)}`
      : "";
    const res = await axios.get(
      `${url}/api/siniestros/verify_dni?dni=${parseInt(
        inputValues.dni
      )}${polizaQuery}`
    );
    console.log(res.data);
    setPolizas((prev) => ({
      ...prev,
      dni: inputValues.dni,
      poliza: inputValues.poliza,
      polizas: res.data,
      message:
        res.data.length > 0 ? "Resultados encontrados" : "Sin resultados",
    }));
    setLoading(false);
  }

  return (
    <Container>
      <FormSection
        icon={<BiSearch />}
        title="Buscá tus pólizas"
        description="En caso de que no recuerdes tu número de póliza, podes buscar solo con tu DNI."
      />
      <PolizaForm
        formValues={{
          inputValues,
          handleChange,
          handleValidation,
          formErrors,
          loading,
        }}
      />
      {polizas?.polizas?.length > 0 && Object.keys(formErrors).length === 0 ? (
        <div className="poliza-container">
          <FormSection
            icon={<BiFile />}
            title="Resultados encontrados"
            description="Lista de pólizas encontradas con tu número de DNI. Seleccioná la póliza por la que necesites denunciar un siniestro."
          />
          {polizas?.polizas?.length > 0 ? (
            <div className="grid-container">
              {polizas.polizas.map((poliza) => {
                return (
                  <Poliza
                    poliza={poliza}
                    nextStep={nextStep}
                    handlePoliza={handlePoliza}
                  />
                );
              })}
            </div>
          ) : null}
        </div>
      ) : null}

      {polizas.message === "Sin resultados" ? (
        <FormSection
          icon={<BiFile />}
          title="No se encontraron resultados"
          description={
            polizas.poliza
              ? `No encontramos resultados para el DNI ${polizas.dni} y la póliza ${polizas.poliza}`
              : `No encontramos pólizas para el DNI ${polizas.dni}`
          }
        />
      ) : null}
    </Container>
  );
};

export default PolizaStep;
