import React, { Component } from "react";
import Container from "./styled";
import Pregunta from "./pregunta";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import "tippy.js/animations/scale-subtle.css";
import "tippy.js/animations/scale-extreme.css";

export default class Faqs extends Component {
  // Estado que determina si esta abierta o no la question
  state = {
    isActiveUno: false,
    isActiveDos: false,
    isActiveTres: false,
    isActiveCuatro: false,
  };

  // Toggle para cambiar el estado cuando se abre o se cierra cada una de las questions
  handleToggleUno = () => {
    this.setState({
      isActiveUno: !this.state.isActiveUno,
      isActiveDos: false,
      isActiveTres: false,
      isActiveCuatro: false,
    });
  };

  handleToggleDos = () => {
    this.setState({
      isActiveUno: false,
      isActiveDos: !this.state.isActiveDos,
      isActiveTres: false,
      isActiveCuatro: false,
    });
  };

  handleToggleTres = () => {
    this.setState({
      isActiveUno: false,
      isActiveDos: false,
      isActiveTres: !this.state.isActiveTres,
      isActiveCuatro: false,
    });
  };

  handleToggleCuatro = () => {
    this.setState({
      isActiveUno: false,
      isActiveDos: false,
      isActiveTres: false,
      isActiveCuatro: !this.state.isActiveCuatro,
    });
  };

  render() {
    return (
      <Container>
        {/* Section Max Width Container */}
        <section id="max-width-container">
          {/* Tag Container */}
          <div id="tag-container">
            {/* Tag */}
            <div>
              <p>FAQs</p>
            </div>
          </div>
          {/* Title */}
          <h3>
            Preguntas <span> más frecuentes</span>
          </h3>
          {/* Preguntas Container */}
          <div id="preguntas-container">
            {/* Pregunta 1 */}
            <div
              className={
                this.state.isActiveUno == true
                  ? "pregunta-container-abierta"
                  : "pregunta-container"
              }
            >
              <Pregunta
                title="¿Desde cuándo comienza a regir mi póliza?"
                clickFunction={this.handleToggleUno}
                isActive={this.state.isActiveUno}
              >
                ¡Es muy simple! Desde nuestro sitio web, seleccioná el seguro
                que quieras contratar, luego podrás cotizar y se contactar con
                vos un asesor comercial que te brindara asesoramiento de manera
                simple y rápida para encontrar la cobertura y seguro que mejor
                se adapte a tus necesidades.
              </Pregunta>
            </div>
            {/* Pregunta 2 */}
            <div
              className={
                this.state.isActiveDos == true
                  ? "pregunta-container-abierta"
                  : "pregunta-container"
              }
            >
              <Pregunta
                title="¿Cómo denuncio un siniestro de manera online?"
                clickFunction={this.handleToggleDos}
                isActive={this.state.isActiveDos}
              >
                ¡Es muy simple! Desde nuestro sitio web, seleccioná el seguro
                que quieras contratar, luego podrás cotizar y se contactar con
                vos un asesor comercial que te brindara asesoramiento de manera
                simple y rápida para encontrar la cobertura y seguro que mejor
                se adapte a tus necesidades.
              </Pregunta>
            </div>
            {/* Pregunta 3 */}
            <div
              className={
                this.state.isActiveTres == true
                  ? "pregunta-container-abierta"
                  : "pregunta-container"
              }
            >
              <Pregunta
                title="¿Cómo puedo contratar un seguro de SeguroWeb?"
                clickFunction={this.handleToggleTres}
                isActive={this.state.isActiveTres}
              >
                ¡Es muy simple! Desde nuestro sitio web, seleccioná el seguro
                que quieras contratar, luego podrás cotizar y se contactar con
                vos un asesor comercial que te brindara asesoramiento de manera
                simple y rápida para encontrar la cobertura y seguro que mejor
                se adapte a tus necesidades.
              </Pregunta>
            </div>
            {/* Pregunta 4 */}
            <div
              className={
                this.state.isActiveCuatro == true
                  ? "pregunta-container-abierta"
                  : "pregunta-container"
              }
            >
              <Pregunta
                title="¿Cómo puedo dar de baja un seguro de SeguroWeb?"
                clickFunction={this.handleToggleCuatro}
                isActive={this.state.isActiveCuatro}
              >
                ¡Es muy simple! Desde nuestro sitio web, seleccioná el seguro
                que quieras contratar, luego podrás cotizar y se contactar con
                vos un asesor comercial que te brindara asesoramiento de manera
                simple y rápida para encontrar la cobertura y seguro que mejor
                se adapte a tus necesidades.
              </Pregunta>
            </div>
          </div>
        </section>
      </Container>
    );
  }
}
