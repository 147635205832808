import Formulario from "../formulario";
import Confirmacion from "../confirmacion";
// Hooks
import { useSwitch } from "../../../hooks/useSwitch";

export default function Switch() {
  const { step, nextStep } = useSwitch();

  switch (step) {
    case 1:
      return <Formulario pasoSiguiente={nextStep}></Formulario>;
    case 2:
      return <Confirmacion></Confirmacion>;
    default:
      return;
  }
}
