import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 0px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
  background-color: var(--blanco);
  border-top: 1px solid var(--gris80);

  // Max Width Container
  #max-width-container {
    width: 1140px;
    margin: 0px 0px 0px 0px;
    padding: 72px 0px 80px 0px;
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
  }

  // Tag
  #tag {
    width: 100%;
    margin: 0px 0px 0px 0px;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    color: var(--verde90);
    line-height: 154.7%;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  // Titulo
  h3 {
    width: 100%;
    margin: 28px 0px 0px 0px;
    font-weight: 500;
    font-size: 25px;
    text-align: center;
    letter-spacing: -0.015em;
    line-height: 42px;

    // Span
    span {
      font-weight: 500;
      color: var(--azul100);
    }
  }

  // Pasos Container
  #pasos-container {
    width: 100%;
    margin: 40px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    justify-content: center;

    // Linea Circulo Container
    .linea-circulo-container {
      width: 7px;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      align-content: flex-start;

      // Linea
      .linea {
        width: 2px;
        height: 72px;
        background: linear-gradient(
          180deg,
          rgba(194, 219, 255, 0.1) 0%,
          rgba(194, 219, 255, 0.8) 26.04%,
          #c2dbff 51.04%,
          #c2dbff 72.4%,
          #c2dbff 100%
        );
        content: "";
      }

      // Primer Linea
      .linea:first-child {
        height: 40px;
      }

      // Circulo
      .circulo {
        width: 6px;
        height: 6px;
        background: #c2dbff;
        border-radius: 300px;
        content: "";
      }
    }

    // Paso Container
    section {
      width: 100%;
      margin: 48px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      align-items: flex-start;
      align-content: flex-start;
      flex-wrap: wrap;
      justify-content: space-between;

      // Data Container
      div {
        width: 42%;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        display: flex;
        align-items: flex-start;
        align-content: flex-start;
        flex-wrap: wrap;
        justify-content: flex-start;

        // Ícono
        img {
          width: 18px;
          margin: 0px 12px 0px 0px;
          user-select: none;
          pointer-events: none;
        }

        // Tag
        p:nth-child(2) {
          width: auto;
          margin: 0px 0px 0px 0px;
          font-weight: 500;
          font-size: 13px;
          text-align: center;
          color: var(--verde90);
          line-height: 154.7%;
          text-transform: uppercase;
          letter-spacing: 2px;
        }

        // Titulo
        h4 {
          width: 100%;
          margin: 28px 0px 0px 0px;
          font-weight: 500;
          font-size: 30px;
          text-align: left;
          letter-spacing: -0.015em;
          line-height: 46px;

          // Strong
          strong {
            font-size: 25px;
            font-weight: 500;
          }

          // Span
          span {
            font-weight: 500;
            color: var(--azul100);
          }
        }

        // Texto
        p:nth-child(4) {
          width: 90%;
          margin: 28px 0px 0px 0px;
          padding: 0px 0px 0px 0px;
          display: flex;
          justify-content: flex-start;
          align-content: center;
          align-items: center;
          flex-wrap: wrap;
          font-family: "DM Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 180%;
          text-align: left;
          letter-spacing: -0.015em;
          color: var(--lila90);
          -webkit-font-smoothing: subpixel-antialiased;
        }
      }

      // Paso Image
      .paso-image {
        width: 510px;
        user-select: none;
        pointer-events: none;
      }
    }

    // Paso Dos Container
    #paso-dos-container {
      margin: 64px 0px 48px 0px;
    }

    // Paso Tres Container
    #paso-tres-container {
      margin: 48px 0px 56px 0px;
    }
  }

  // Asegurado Container
  #asegurado-container {
    width: 600px;
    margin: 56px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    // Ícono
    img {
      width: 18px;
      margin: 0px 0px 0px 0px;
      user-select: none;
      pointer-events: none;
    }

    // Título
    h4 {
      width: 100%;
      margin: 16px 0px 0px 0px;
      font-weight: 500;
      font-size: 25px;
      text-align: center;
      letter-spacing: -0.015em;
      line-height: 46px;

      // Span
      span {
        font-size: 30px;
        font-weight: 500;
        color: var(--azul100);
      }
    }

    // Texto
    p {
      width: 100%;
      margin: 16px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 180%;
      text-align: center;
      letter-spacing: -0.015em;
      color: var(--lila90);
      -webkit-font-smoothing: subpixel-antialiased;
    }

    // Botón
    a {
      width: auto;
      margin: 40px 0px 0px 0px;
      padding: 14px 26px 14px 26px;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      background-color: var(--verde50);
      border-radius: 8px;
      border: 0;
      text-decoration: none;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 154.7%;
      text-align: center;
      color: var(--blanco);
      transition: 0.2s all;

      // Ícono
      span {
        margin: 1px 0px 0px 8px;
        font-size: 20px;
        transition: 0.2s all;
      }
    }

    // Botón Hover
    a:hover {
      background-color: var(--verde90);

      // Ícono
      span {
        transform: translate(8px, 0px);
      }
    }
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    // Max Width Container
    #max-width-container {
      width: 1000px;
    }

    // Pasos Container
    #pasos-container {
      // Paso Container
      section {
        // Data Container
        div {
          width: 44%;

          // Texto
          p:nth-child(4) {
            width: 100%;
          }
        }

        // Paso Image
        .paso-image {
          width: 490px;
          user-select: none;
          pointer-events: none;
        }
      }
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    #max-width-container {
      width: 680px;
    }

    // Pasos Container
    #pasos-container {
      // Paso Container
      section {
        justify-content: center;

        // Data Container
        div {
          width: 100%;
          justify-content: center;

          // Titulo
          h4 {
            text-align: center;
          }

          // Texto
          p:nth-child(4) {
            width: 84%;
            text-align: center;
          }
        }

        // Paso Image
        .paso-image {
          width: 510px;
          margin: 56px 0px 56px 0px;
        }
      }

      // Paso 2 Container
      #paso-dos-container {
        margin: 64px 0px 0px 0px;
        align-items: center;
        flex-direction: column-reverse;
      }

      // Paso 3 Container
      #paso-tres-container {
        margin: 64px 0px 0px 0px;
      }
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Max Width Container
    #max-width-container {
      width: 480px;
    }

    // Pasos Container
    #pasos-container {
      // Linea Circulo Container
      .linea-circulo-container {
        // Primer Linea
        .linea:first-child {
          height: 20px;
        }
      }

      // Paso Container
      section {
        // Data Container
        div {
          // Texto
          p:nth-child(4) {
            width: 100%;
          }
        }

        // Paso Image
        .paso-image {
          width: 480px;
        }
      }
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Max Width Container
    #max-width-container {
      width: 400px;
    }

    // Pasos Container
    #pasos-container {
      // Paso Container
      section {
        // Paso Image
        .paso-image {
          width: 100%;
        }
      }
    }
  }

  // 525px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile525}) {
    // Max Width Container
    #max-width-container {
      max-width: 85%;
    }
  }
`;

export default Container;
