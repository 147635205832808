import React, { useEffect } from "react";
import { BsCheckLg } from "react-icons/bs";
import Container from "./styled";
import AnimacionExito from "../../AnimacionExito"
import { useScrollTop } from "../../../hooks/useScrollTop";
import { BsWhatsapp } from "react-icons/bs";
import { TbMail } from "react-icons/tb";
import { BiPhone } from "react-icons/bi";

const ConfirmacionStep = () => {
  useScrollTop();

  return (
    <Container>
      {/* Contrataste Container */}
      <div className="contrataste-container opacidad04">
        {/* Animación Container */}
        <div id="animacion-container">
          <span></span>
          <AnimacionExito />
        </div>
        {/* Texto Agradecimiento */}
        <div className="texto-container">
          <div>
            <p id="texto-contacto">
              Nos pondremos en contacto con vos a la brevedad para continuar el
              proceso.
            </p>
            <p id="texto-contacto">
              ¡Muchas gracias por confiar en SeguroWeb!
            </p>
          </div>
          <p id="texto-contacto" className="texto-agradecimiento">
            Atentamente,{" "}
            <strong className="texto-agradecimiento-area">
              Área de Siniestros de SeguroWeb
            </strong>
          </p>
          <div className="canal">
          {/* Botón WhatsApp 1 */}
          <a
            id="wppCanal3Frist"
            href="https://api.whatsapp.com/send?phone=541128061469&text=%C2%A1Hola,%20necesito%20atención%20de%20siniestros%20de%20SeguroBici!%20Ingresé%20desde%20la%20web."
            target="_blank"
          >
            <span>
              <BsWhatsapp />
            </span>
            11-2806-1469
          </a>
          <p>|</p>
          <a
            href="https://api.whatsapp.com/send?phone=541162096951&text=%C2%A1Hola,%20necesito%20atención%20de%20siniestros%20de%20SeguroBici!%20Ingresé%20desde%20la%20web."
            target="_blank"
            id="boton-telefono"
          >
            <span>
              <BiPhone />
            </span>
            0810-220-2373 (opción 4)
          </a>
          <p>|</p>
          {/* Botón Mail */}
          <a id="boton-mail" href="mailto:siniestros@seguroweb.com.ar">
            <span>
              <TbMail />
            </span>
            siniestros@seguroweb.com.ar
          </a>
        </div>
        </div>

      </div>
    </Container>
  );
};

export default ConfirmacionStep;
