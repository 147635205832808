import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 230px !important;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 268px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-self: flex-start;
  z-index: 96;
  position: -webkit-sticky;
  position: sticky;
  top: 200px;

  // Plazos Container Titulo
  p {
    width: 100%;
    margin: 0px 0px 12px 0px;
    font-size: 13px;
    font-weight: 500;
    color: var(--verde90);
  }

  // Nav Container Div
  div {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 12px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    border-top: 1px solid var(--gris90);
  }

  // Plazos Container Botones
  a {
    width: 100%;
    margin: 0px 0px 12px 0px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 185%;
    text-align: left;
    letter-spacing: -0.015em;
    color: var(--lila90);
    transition: 0.2s all;
  }

  // Nav Container Botones Hover
  a:hover {
    opacity: 0.5;
  }
`;

export default Container;
