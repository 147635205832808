import React from "react";
// Estilos
import Container from "./styled";
// Librerías
import { Link } from "react-scroll";
// Utils
import { goToTop } from "../../../utils/scrollToTop";

export default function Aside() {
  return (
    <Container>
      {/* Subtitulo */}
      <p>Índice</p>
      <Link
        to="resguardar"
        smooth={true}
        spy={true}
        duration={700}
        offset={-100}
      >
        Resguardar su privacidad
      </Link>
      <Link
        to="direccion-nacional"
        smooth={true}
        spy={true}
        duration={700}
        offset={-100}
      >
        Dir. Protección Datos Personales
      </Link>
      <Link
        to="visitantes"
        smooth={true}
        spy={true}
        duration={700}
        offset={-100}
      >
        Visitantes del sitio web
      </Link>
      <Link
        to="protegemos-datos-personales"
        smooth={true}
        spy={true}
        duration={700}
        offset={-100}
      >
        Protegemos sus datos personales
      </Link>
      <Link
        to="seguro-bici"
        smooth={true}
        spy={true}
        duration={700}
        offset={-100}
      >
        SeguroWeb
      </Link>
      <Link
        to="producto-ofrecido"
        smooth={true}
        spy={true}
        duration={700}
        offset={-100}
      >
        Sobre el producto ofrecido
      </Link>
      <Link
        to="responsabilidad"
        smooth={true}
        spy={true}
        duration={700}
        offset={-100}
      >
        Responsabilidad limitada
      </Link>
      <Link
        to="jurisdiccion"
        smooth={true}
        spy={true}
        duration={700}
        offset={-100}
      >
        Jurisdicción
      </Link>
    </Container>
  );
}
