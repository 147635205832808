import React from "react";
// Estilos
import Container from "./styled";
// Librerías
import { Link as Boton } from "react-router-dom";
// Utils
import { goToTop } from "../../../utils/scrollToTop";

export default function Aside() {
  return (
    <Container>
      {/* Subtitulo */}
      <p>SeguroTecno</p>
      {/* Texto */}
      <p id="plazos-texto">
        Próximamente todos nuestros seguros de productos de tecnología tendrán su propio seguro, ¡SeguroTecno!.
      </p>
      {/* Ver más */}
      <div>
        {/* Subtitulo */}
        <p>Ver más</p>
        {/* Botón */}
        <Boton to="/" onClick={goToTop}>
          Seguros
        </Boton>
      </div>
    </Container>
  );
}
