import styled from "styled-components";
import { breakpoints } from "../../../../constants/media";

const Container = styled.div`
  width: 377.3px;
  height: 126px;
  margin: ${(props) =>
    props.tipo == "siniestros" ? "0px 0px 0px 0px" : "28px 0px 0px 0px"};
  padding: 26px 28px 28px 28px;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
  background-color: var(--blanco);
  border-radius: 10px;
  border: 1px solid var(--gris80);

  // Titulo
  h3 {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-weight: 500;
    font-size: 17px;
    text-align: left;
    line-height: 145.5%;
    letter-spacing: -0.015em;
    color: var(--negro);

    span {
      color: var(--azul100);
      font-weight: 500;
    }
  }

  // Texto
  p {
    width: 100%;
    margin: 6px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 190%;
    text-align: left;
    letter-spacing: -0.015em;
    color: var(--lila90);
  }

  .boton-siniestros {
    margin: 6px 0 0 0;
    padding: 9px 18px 9px 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: var(--azul100);
    border-radius: 7px;
    color: var(--blanco);
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: -0.015em;
    text-decoration: none;
    transition: 0.2s all;

    span {
      height: 100%;
      margin: 0px 6px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      font-size: 15px;
    }
  }

      // Botón
    .link {
      width: auto;
      padding: 0px 0px 0px 0px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      align-content: center;
      font-weight: 500;
      font-size: 13px;
      line-height: 180%;
      color: var(--verde50);
      transition: 0.2s all;
      cursor: pointer;

      // Span
      span {
        margin: 5px 0px 0px 4px;
        font-size: 20px;
        transition: 0.2s all;
      }

      :hover {
        color: var(--verde90);

        // Span
        span {
          color: var(--verde90);
          transform: translate(8px, 0px);
        }
      }
    }

  // Botones WhatsApp
  .botones-whatsapp {
    width: 100%;
    margin: 10px 0px 0px 0px;
    padding: 11px 0px 0px 0px;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    justify-content: flex-start;
    border-top: 1px solid #e8f0ff;

    // Botón WhatsApp
    a {
      width: auto;
      margin: 0px 12px 0px 0px;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      text-decoration: none;
      color: var(--verde50);
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 192.7%;
      opacity: 1;
      cursor: pointer;
      transition: 0.2s;

      // Ícono
      span {
        margin: 3px 6px 0px 0px;
        color: var(--verde50);
        font-size: 13px;
        opacity: 1;
        transition: 0.2s;
      }

      #icono-telefono {
        margin: 3.5px 6px 0px 0px;
        font-size: 16px;
      }

      // Hover
      :hover {
        opacity: 0.7;

        span {
          opacity: 0.8;
        }
      }
    }
  }

  .botones-whatsapp-siniestros {
    border: none;
    margin: 6px 0 0 0;
    padding: 0;
  }

  // Botón Mail
  #boton-mail {
    width: auto;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-decoration: none;
    color: var(--gris);
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 192.7%;
    opacity: 1;
    cursor: pointer;
    transition: 0.2s;

    span {
      margin: 4.5px 5px 0px 0px;
      font-size: 14px;
      color: var(--gris);
    }

    // Hover
    :hover {
      opacity: 0.7;

      span {
        opacity: 0.8;
      }
    }
  }


  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    
  margin: ${(props) =>
    props.tipo == "siniestros" ? "0px 0px 0px 0px" : "26px 0px 0px 0px"};
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    width: calc(328px - 56px);
    margin: 28px 0px 0px 0px;
  }



  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    width: 100%;
  }

  // 525px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile525}) {
    height: auto;
  }
`;

export default Container;
