import React, { useState } from "react";
// Estilos
import Container from "./styled";
// Componentes
import Redes from "./redes";
import Ssn from "./ssn";
// Librerías
import { Link } from "react-router-dom";
import { HiOutlineArrowLongRight } from "react-icons/hi2";

export default function Footer({ link, pagina }) {
  return (
    <Container pagina={pagina}>
      <div id="border-gradient"></div>
      {/* Max Width Container */}
      <div id="max-width-container">
        {/* Logo Data Container */}
        <div id="logo-data-container">
          {/* Logo */}
          <Link to="/">
            <img alt="Logo de SeguroWeb" src="/logos/gys/seguroweb.svg"></img>
          </Link>
          {/* Data */}
          <div>
            <a href="tel:08102202373">0810-220-2373</a>
            <a href="tel:1152632581">(011) 5263-2581</a>
            <p>info@seguroweb.com.ar</p>
            <p>Suipacha 245 1°, CABA</p>
          </div>
        </div>
        {/* Navs Container */}
        <div id="navs-container">
          {/* Columna */}
          <div>
            {/* Titulo */}
            <p>Mapa del sitio</p>
            {/* Nav */}
            <nav>
              <li>
                <Link to="/">Inicio</Link>
              </li>
              <li>
                <Link to="/">Seguros</Link>
              </li>
              <li>
                <Link to="/">Referidos</Link>
              </li>
              <li>
                <Link to="/">Contacto</Link>
              </li>
            </nav>
          </div>
          {/* Column Nav Container */}
          <div>
            {/* Titulo */}
            <p>La empresa</p>
            {/* Nav */}
            <nav>
              <li>
                <a href="https://gys.com.ar/" target="_blank">
                  Gestión y Servicios S.R.L.
                </a>
              </li>
              <li>
                <Link to="/sobre-nosotros">Sobre nosotros</Link>
              </li>
              <li>
                <Link to="/forma-parte">Trabajá en SeguroWeb</Link>
              </li>
              <li>
                <Link to="/politicas-y-terminos">Políticas y términos</Link>
              </li>
            </nav>
          </div>
        </div>
        {/* Arrepentimiento Baja Container */}
        <div id="arrepentimiento-baja-container">
          <div>
            <h4>
              Botón de <span>arrepentimiento</span>
            </h4>
            <p>¿Te arrepentiste de tu solicitud?</p>
            <Link to="/arrepentimiento">
              Ingresá
              <span>
                <HiOutlineArrowLongRight />
              </span>
            </Link>
          </div>
          <div>
            <h4>
              Botón de <span>baja</span>
            </h4>
            <p>¿Querés dar de baja un seguro?</p>
            <Link to="/baja">
              Ingresá
              <span>
                <HiOutlineArrowLongRight />
              </span>
            </Link>
          </div>
        </div>
      </div>
      {/* Redes Component */}
      <Redes></Redes>
      {/* SSN Component */}
      <Ssn></Ssn>
    </Container>
  );
}
