import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  max-width: 1920px;
  background-color: var(--blanco);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .poliza-container {
    width: 100%;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
  }

  .margin-temporary {
    margin-bottom: 80px;
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {

  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {

    .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 480px;
    }

    // Max Width Coberturas Container
    #max-width-coberturas-container {
      max-width: 480px;
    }

    // Formulario
    form {
      max-width: 480px;
    }

    // Datos Vehiculo Container
    .datos-vehiculo-container {
      width: 100%;
    }

    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container,
    .select-container,
    #input-cuit-dni-container,
    #input-codigo-area-container,
    #input-vencimiento-container,
    #input-telefono-container,
    #input-rodado-container {
      width: 232.5px;
      max-width: 232.5px;
      margin: 30px 15px 0px 0px;
    }

    // Input Apellido, Input Código Postal, Input Código Área, Input Banco Emisor, Input Titular, Input CBU
    #input-apellido-container,
    #input-codigo-postal-container,
    #input-codigo-area-container,
    #input-modelo-container,
    #input-banco-emisor-container,
    #input-titular-container,
    #input-cbu-container,
    #input-color-container {
      margin: 30px 0px 0px 0px;
    }

    #input-apellido-container,
    #input-banco-emisor-container,
    #input-titular-container {
      text-transform: capitalize !important;
    }

    .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 380px;
    }

    // Max Width Coberturas Container
    #max-width-coberturas-container {
      max-width: 380px;
    }

    // Formulario
    form {
      max-width: 380px;
    }

    // Datos Cotización
    #datos-cotizacion {
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
    }

    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container,
    .select-container,
    #input-cuit-dni-container,
    #input-codigo-area-container,
    #input-vencimiento-container,
    #input-telefono-container {
      width: calc(50% - 7.5px) !important;
      max-width: calc(50% - 7.5px) !important;
    }

    #datos-tarjeta-credito {
      width: 100%;
    }

    .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }
  }

  // 450px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile450}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 85%;
    }

    // Max Width Coberturas Container
    #max-width-coberturas-container {
      max-width: 85%;
      justify-content: center;
    }

    // Logo y Plan Container
    .logo-plan-container {
      padding: 0px 0px 0px 0px;
      border-right: 0px;
    }

    // Checks Container
    .checks-container {
      display: none;
    }

    // Coberturas Container
    .coberturas-container {
      max-width: 115px;
    }

    // Formulario
    form {
      max-width: 85%;
    }

    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container,
    #input-cuit-dni-container,
    #input-codigo-area-container,
    #input-vencimiento-container,
    #input-telefono-container,
    .select-container {
      width: 100% !important;
      max-width: 100% !important;
      margin: 30px 0px 0px 0px !important;
    }

    .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }
  }

  // 350px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile350}) {
    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container,
    #input-cuit-dni-container,
    #input-codigo-area-container,
    #input-vencimiento-container,
    #input-telefono-container {
      width: 100%;
      max-width: 100%;
      margin: 30px 0px 0px 0px;
    }

    .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export default Container;
