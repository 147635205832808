import * as React from "react";
// Estilos
import Container from "./styled";
import { Link } from "react-router-dom";

export default function CardOtros() {
  return (
    <Container>
      {/* Card Container */}
      <section id="card-container">
        {/* Título Texto Container */}
        <div id="titulo-texto-container">
          {/* Título */}
          <h4>
            ¡Y <span>mucho más</span>!
          </h4>
          {/* Texto */}
          <p>Más de 40 productos asegurables</p>
        </div>
        {/* Texto Botón Container */}
        <div id="texto-boton-container">
          {/* Text */}
          <p>Conocé todos nuestros otros seguros y consultanos.</p>
          {/* Botón */}
          <Link to="/otros-seguros">Ver más</Link>
        </div>
      </section>
    </Container>
  );
}
