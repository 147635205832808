import React from "react";
// Estilos
import Container from "./styled";
// Utils
import useWindowDimensions from "../../../../utils/useWindowDimensions";

export default function Estadisticas() {
  // Estado que guarda las dimensiones de la pantalla
  const { height, width } = useWindowDimensions();

  return (
    <Container>
      {/* Estadistica */}
      <div className="estadistica-container">
        <h3>
          <span>+</span> 80.000
        </h3>
        <p>Clientes satisfechos</p>
        <p>Aseguramos grandes clientes que confían en nosotros.</p>
      </div>
      {/* Separación */}
      {width >= 651 && (
        <div className="separacion">
          <hr></hr>
        </div>
      )}
      {/* Estadistica */}
      {width >= 651 && (
        <div className="estadistica-container">
          <h3>
            <span>+</span>150.000
          </h3>
          <p>Siniestros cubiertos</p>
          <p>Con asistencia las 24hs. ante un siniestro de cualquier tipo.</p>
        </div>
      )}
      {/* Separación */}
      {width >= 1200 && (
        <div className="separacion">
          <hr></hr>
        </div>
      )}
      {/* Estadistica */}
      {width >= 1200 && (
        <div className="estadistica-container">
          <h3>
            <span>+</span>30
          </h3>
          <p>Socios comerciales</p>
          <p>Trabajamos con muchas aseguradoras de primer nivel.</p>
        </div>
      )}
    </Container>
  );
}
