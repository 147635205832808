import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  margin: 0px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  // Puntos de fondo
  background: linear-gradient(
        90deg,
        var(--celeste90) calc(22px - 1.4px),
        transparent 1%
      )
      center / 22px 22px,
    linear-gradient(var(--celeste90) calc(22px - 1.4px), transparent 1%) center /
      22px 22px,
    #98a5be;

  // Título Max Width Container
  #titulo-max-width-container {
    width: 670px;
    margin: 0px 0px 0px 0px;
    padding: 48px 0px 48px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    border-bottom: 1px solid var(--gris80);

    // Tag
    #tag {
      width: auto;
      margin: 0px 0px 0px 0px;
      font-weight: 500;
      font-size: 13px;
      text-align: center;
      color: var(--verde90);
      line-height: 154.7%;
      text-transform: uppercase;
      letter-spacing: 2px;
    }

    // Título
    h3 {
      width: 100%;
      margin: 20px 0px 0px 0px;
      font-weight: 500;
      font-size: 30px;
      text-align: left;
      letter-spacing: -0.015em;
      line-height: 38px;

      // Span
      span {
        color: var(--azul100);
      }
    }

    // Texto
    #texto {
      width: 100%;
      margin: 24px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
      flex-wrap: wrap;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 190%;
      text-align: left;
      letter-spacing: -0.015em;
      color: var(--lila90);
    }
  }
`;

export default Container;
