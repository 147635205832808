import React, { useEffect } from "react";
// Librerías
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
// Inicio
import InicioPage from "../pages/inicioPage";
// Sobre nosotros
import SobreNosotrosPage from "../pages/sobreNosotrosPage";
// Arrepentimiento
import ArrepentimientoPage from "../pages/arrepentimientoPage";
// Baja de servicio
import BajaPage from "../pages/bajaPage";
// Otros Seguros
import OtrosSegurosPage from "../pages/otrosSegurosPage";
// Forma Parte
import FormaPartePage from "../pages/formaPartePage";
// Políticas y Términos
import PoliticasTerminosPage from "../pages/politicasTerminosPage";
import SiniestrosPage from "../pages/Siniestros";

export default function AnimatedRoutes() {
  const location = useLocation();

  {/* Redirección para /camiones */}
  useEffect(() => {
    // Verificar si la ruta es /camiones y redirigir
    if (location.pathname === "/camiones") {
      window.location.href = "https://camiones.seguroweb.com.ar";
    }
  }, [location.pathname]);

  return (
      <Routes location={location} key={location.pathname}>
        
        {/* Inicio */}
        <Route exact path="/" element={<InicioPage />} />
        {/* Sobre nosotros */}
        <Route exact path="/sobre-nosotros" element={<SobreNosotrosPage />} />
        {/* Arrepentimiento */}
        <Route
          exact
          path="/arrepentimiento"
          element={<ArrepentimientoPage />}
        />
        {/* Baja */}
        <Route exact path="/baja" element={<BajaPage />} />
        {/* Otros Seguros */}
        <Route exact path="/otros-seguros" element={<OtrosSegurosPage />} />
        {/* Forma Parte Page */}
        <Route exact path="/forma-parte" element={<FormaPartePage />} />

        <Route exact path="/siniestros" element={<SiniestrosPage />} />

        {/* Políticas y Términos Page */}
        <Route
          exact
          path="/politicas-y-terminos"
          element={<PoliticasTerminosPage />}
        />
        <Route path="/*" element={ <Navigate to="/" /> }/>
      </Routes>
  );
}
