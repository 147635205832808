import Container from "./styled";
const FormSection = ({ icon, title, description, children }) => {
  return (
    <Container>
      <div className="section">
        <h4>
          <span>
            {icon}
          </span>
          {title}
        </h4>
        <p>{description}</p>
      </div>
      {children}
    </Container>
  );
};

export default FormSection;
