import React from "react";
// Estilos
import Container from "./styled";
// Componentes
import Card from "./card";
import CardNumeros from "./card-numeros";
import CardEstrellas from "./card-estrellas";
import CardSlider from "./card-slider";
// Librerías
import Slider from "infinite-react-carousel";

export default function Introduccion() {
  return (
    <Container>
      {/* Section Max Width Container */}
      <section id="max-width-container">
        {/* Left Zone */}
        <div id="left-zone">
          <Card />
          <Card tipo="beneficios" />
          <CardEstrellas />
        </div>
        {/* Right Zone */}
        <div id="right-zone">
          <CardNumeros tipo="siniestros" />
          <CardNumeros />
          <Slider
            arrows={false}
            arrowsBlock={false}
            autoplay={true}
            dots={false}
            duration={300}
            autoplaySpeed={6000}
            slidesToShow={1}
            wheel={true}
            className="carousel"
          >
            <CardSlider
              imagen="/home/introduccion/slider/tamara.png"
              nombre="Tamara González"
              estrellas="5"
              opinion="Desde el momento de la denuncia, contestaron todo rápidamente. Me
              explicaron todo de muy buena forma. Muy amables, los sigo eligiendo."
            />
            <CardSlider
              imagen="/home/introduccion/slider/adriana.png"
              nombre="Adriana Velasco"
              estrellas="5"
              opinion="Agradecida por la rapidez en resolver el siniestro y el pago, así como por la excelente atención de Adriana y el sector correspondiente. 
              Un seguro totalmente confiable y dedicado, algo poco común hoy en día. 
              RECOMENDABLE 100%. MUCHAS GRACIAS POR TODO!!!"
            />
            <CardSlider
              imagen="/home/introduccion/slider/Juan.png"
              nombre="Juan Tántera"
              estrellas="5"
              opinion="Desde el inicio del trámite, en donde me asesoraron en forma concreta y rápida, hasta el cobro del mismo el servicio fue excelente
              No solo por la efectividad de todo, sino por la simpleza y velocidad en la que todo aconteció. Me fueron informando paso a paso cómo hacer y en qué estado estaba el trámite."
            />
            <CardSlider
              imagen="/home/introduccion/slider/Micaela.png"
              nombre="Micaela Laura"
              estrellas="5"
              opinion="Excelente siempre me responden en tiempo y forma, Vanesa una genia!
              El seguro me cubrió y respondió siempre muy agradecida lo recomiendo el 100 x 💯💪"
            />
          </Slider>
        </div>
      </section>
    </Container>
  );
}
