import Container from "./styled";
import InputBasic from "../../InputBasic";
import Button from "../../Button";

const PolizaForm = ({ formValues, inputsRef }) => {

  const { inputValues, handleChange, formErrors, handleValidation, loading } = formValues
  
  return (
    <Container>
      {/* Input Nombre */}
      <InputBasic 
        label="DNI"
        type="text"
        id="input-dni"
        name="dni"
        onChange={handleChange}
        onFocus={handleChange}
        onBlur={handleChange}
        value={inputValues.dni}
        className={formErrors.dni == null ? "" : "input-error"}
        placeholder="40955588"
        ref={(element) => (inputsRef.current["dni"] = element)}
        error={formErrors.dni}
      />
      {/* Input Apellido */}
      <InputBasic 
        label="Póliza"
        type="text"
        id="input-poliza"
        name="poliza"
        onChange={handleChange}
        onFocus={handleChange}
        onBlur={handleChange}
        value={inputValues.poliza}
        className={formErrors.poliza == null ? "" : "input-error"}
        placeholder="Póliza"
        ref={(element) => (inputsRef.current["poliza"] = element)}
      />
      <Button onClick={handleValidation} disabled={loading}>{loading ? "Buscando..." : "Buscar"}</Button>
    </Container>
  );
};

export default PolizaForm;
