import React, { useRef } from "react";
import { bool } from "prop-types";
import Container from "./styled";
import disableScroll from "disable-scroll";
import { IoClose } from "react-icons/io5";

// Prop Types
Menu.propTypes = {
  open: bool.isRequired,
};

export default function Menu({ open, active, setOpen, useOutsideAlerter }) {
  const toggleOpen = () => {
    setOpen(!open);
    disableScroll.off();
  };

  const referencia = useRef(null);
  useOutsideAlerter(referencia);

  return (
    <Container open={open} ref={referencia}>
      {/* Botón Cerrar */}
      <button onClick={toggleOpen}>
        <IoClose></IoClose>
      </button>
      {/* Botones Container */}
      <div id="botones-container">
        <hr />
        {/* Inicio */}
        <li>
          <a target="_blank" href="https://www.segurobici.com.ar/">
            <img
              alt="Escudo de SeguroBici"
              src="/logos/gys/escudoscolor/segurobici.svg"
            ></img>
            SeguroBici
          </a>
        </li>
        {/* Decálogo */}
        <hr />
        <li>
          <a target="_blank" href="https://seguromotos.com.ar/">
            <img
              alt="Escudo de SeguroMotos"
              src="/logos/gys/escudoscolor/seguromoto.svg"
            ></img>
            SeguroMotos
          </a>
        </li>
        {/* Aseguradoras */}
        <hr />
        <li>
          <a target="_blank" href="https://www.segurohogar.com.ar/">
            <img
              alt="Escudo de SeguroHogar"
              src="/logos/gys/escudoscolor/segurohogar.svg" //client\public\logos\gys\escudos
            ></img>
            SeguroHogar
          </a>
        </li>
        {/* Servicios */}
        <hr />
        <li>
          <a target="_blank" href="https://www.seguromascotas.com.ar/">
            <img
              alt="Escudo de SeguroMascotas"
              src="/logos/gys/escudoscolor/seguromascota.svg"
            ></img>
            SeguroMascotas
          </a>
        </li>
        {/* Consultanos */}
        <hr />
        <li>
          <a target="_blank" href="https://auto.seguroweb.com.ar/">
            <img
              alt="Escudo de SeguroAuto"
              src="/logos/gys/escudoscolor/seguroauto.svg"
            ></img>
            SeguroAuto
          </a>
        </li>
        <hr />
        <li>
          <a target="_blank" href="https://comercio.seguroweb.com.ar/">
            <img
              alt="Escudo de SeguroComercio"
              src="/logos/gys/escudoscolor/segurocomercio.svg"
            ></img>
            SeguroComercio
          </a>
        </li>
        <hr />
      </div>
    </Container>
  );
}
