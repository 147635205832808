import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 0px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--celeste90);
  border-top: 1px solid var(--gris80);

  // Max Width Container
  section {
    width: 1140px;
    margin: 0px 0px 0px 0px;
    padding: 88px 0px 88px 0px;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    position: relative;
    overflow: hidden;

    #background-image {
      width: 1000px;
      position: absolute;
      top: 0;
      right: 130px;
      z-index: 80;
      opacity: 0.5;
      pointer-events: none;
      transform: rotate(90deg);
    }
  }

  // Data Container
  #data-container {
    width: calc(50% - 20px);
    height: 100%;
    margin: 0px 20px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    view-timeline-name: --moviendo;
    view-timeline-axis: block;
    animation: linear mover both;
    animation-timeline: --moviendo;
    animation-range: entry 35% cover 45%;
    z-index: 98 !important;

    // Tag Container
    #tag-container {
      width: 100%;
      padding: 0px 0px 0px 0px;
      margin: 0px 0px 0px 0px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;

      // Tag
      div {
        width: auto;
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;

        // Tag Text
        p {
          width: auto;
          margin: 0px 0px 0px 0px;
          font-weight: 500;
          font-size: 13px;
          text-align: center;
          color: var(--verde90);
          line-height: 154.7%;
          text-transform: uppercase;
          letter-spacing: 2px;
        }
      }
    }

    h3 {
      width: 85%;
      margin: 28px 0px 0px 0px;
      font-weight: 500;
      font-size: 30px;
      text-align: left;
      letter-spacing: -0.025em;
      line-height: 44px;

      // Span
      span {
        color: var(--azul100);
      }
    }

    h4 {
      width: 90%;
      margin: 12px 0px 0px 0px;
      font-weight: 500;
      font-size: 20px;
      text-align: left;
      letter-spacing: -0.025em;
      line-height: 40px;
    }

    p, .text {
      width: 440px;
      margin: 28px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
      flex-wrap: wrap;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 190%;
      text-align: left;
      letter-spacing: -0.015em;
      color: var(--lila90);

      // Icon
      span {
        margin: 4px 6px 0px 0px;
        font-size: 16px;
      }
    }
  }

  // Adress
  #adress {
    margin: 62px 0px 0px 0px !important;
    align-items: flex-end !important;
    align-content: flex-end !important;
  }

  // Formulario Container
  #formulario-container {
    width: calc(50% - 20px);
    margin: 0px 0px 0px 20px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: space-between;
    view-timeline-name: --mostrando;
    view-timeline-axis: block;
    animation: linear mostrar both;
    animation-timeline: --mostrando;
    animation-range: entry 35% cover 38%;
    z-index: 98 !important;

    form {
      width: 100%;
      margin: 0px 0px 0px 0px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      align-content: flex-start;
      justify-content: space-between;
      flex-direction: row-reverse;

      // Input Container
      .input-container {
        width: calc(50% - 16px);
        margin: 0px 0px 0px 0px;
      }

      // Textarea Container
      #textarea-mensaje-container {
        width: 100%;
        margin: 32px 0px 0px 0px;
      }

      // Input y Textarea
      .input-container input,
      .input-container textarea {
        width: 100%;
        padding: 14px 20px 14px 20px;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        font-weight: 400;
        color: var(--negro);
        border: 1.7px solid var(--gris80);
        background-color: var(--blanco);
        -webkit-appearance: none;
        box-shadow: 0;
        transition: 0.2s all;
        border-radius: 8px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        text-rendering: optimizeLegibility;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        z-index: 58 !important;

        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        // Firefox
        [type="number"] {
          -moz-appearance: textfield !important;
        }
      }

      #input-nombre-container input,
      #input-apellido-container input {
        margin: 0px 0px 32px 0px;
        text-transform: capitalize !important;
      }

      // Input Error
      .input-error {
        border: 1px solid red !important;
      }

      // Textarea
      .input-container textarea {
        width: 100% !important;
        height: 140px !important;
        padding: 20px 20px 20px 20px !important;
        resize: none;
      }

      // Input and Textarea Focus
      .input-container input:focus,
      .input-container textarea:focus {
        border: 1.7px solid var(--gris90);
        outline: none;
        z-index: 59 !important;
      }

      // Button
      button {
        width: auto;
        margin: 30px 0px 0px 0px;
        padding: 14px 26px 14px 26px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        background-color: var(--azul100);
        border-radius: 8px;
        border: 0;
        text-decoration: none;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 154.7%;
        text-align: center;
        color: var(--blanco);
        transition: 0.2s all;

        // Ícono
        span {
          margin: 1px 0px 0px 8px;
          font-size: 20px;
          transition: 0.2s all;
        }

        :hover {
          background-color: var(--azul40);

          span {
            transform: translate(8px, 0px);
          }
        }
      }
    }
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    // Max Width Container
    section {
      width: 1000px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    position: relative;
    overflow: hidden;

    // Max Width Container
    section {
      width: 680px;
      justify-content: center;
      position: unset;

      // Background Image
      #background-image {
        width: 500px;
        top: 0;
        left: 0 !important;
        right: unset !important;
        z-index: 80;
      }
    }

    // Data Container
    #data-container {
      width: 100%;
      height: auto;
      margin: 0px 0px 0px 0px;
      justify-content: center;

      // Tag Container
      #tag-container {
        justify-content: center;
      }

      h3 {
        width: 100%;
        text-align: center;
      }

      // Título
      h4 {
        text-align: center;
      }

      // Texto
      p {
        text-align: center;
      }

      // Adress
      #adress {
        display: none;
      }
    }

    // Formulario Container
    #formulario-container {
      width: 90%;
      margin: 56px 0px 0px 0px;
      z-index: 98 !important;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Max Width Container
    section {
      width: 480px;
    }

    // Formulario Container
    #formulario-container {
      width: 100%;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Max Width Container
    section {
      width: 400px;
    }
  }

  // 525px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile525}) {
    // Max Width Container
    section {
      max-width: 85%;
    }

    // Formulario Container
    #formulario-container {
      width: 100%;

      form {
        // Input Container
        .input-container {
          width: 100%;
          margin: 16px 0px 0px 0px !important;
        }

        #input-nombre-container {
          margin: 0px 0px 0px 0px;
        }

        #input-nombre-container input,
        #input-apellido-container input {
          margin: 0px 0px 0px 0px;
        }
      }
    }
  }
`;

export default Container;
