import React, { useState, useEffect } from "react";
// Estilos
import Container from "./styled";
// Librerías
import { FaFacebookF } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";
import { AiOutlineFire } from "react-icons/ai";
import { TfiWorld } from "react-icons/tfi";
import disableScroll from "disable-scroll";
import { Link } from "react-router-dom";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import { Tooltip } from 'react-tooltip'
import "react-tooltip/dist/react-tooltip.css";
// Componentes
import Burger from "./burger";
import Menu from "./menu";
// Utils
import useWindowDimensions from "../../utils/useWindowDimensions";

export default function Header() {
  const [headerColor, setHeaderColor] = useState("header-container-gray");
  const [open, setOpen] = useState(false);
  const { height, width } = useWindowDimensions();

  const listenScrollEvent = (event) => {
    if (window.scrollY < (width > 850 ? 692 : width > 470 ? 624 : 532)) {
      return setHeaderColor("header-container-gray");
    } else if (window.scrollY > (width > 850 ? 692 : width > 470 ? 624 : 532)) {
      return setHeaderColor("header-container-white");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
          disableScroll.off();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <Container name="header">
      <div className={headerColor}>
        <div id="logo-data-container">
          <Burger open={open} setOpen={setOpen} />
          <Menu
            open={open}
            setOpen={setOpen}
            useOutsideAlerter={useOutsideAlerter}
          />
          <div id="logo-escudos-container">
            {/* Logo */}
            <h1>
              <Link to="/">
                <img
                  alt="Logo de SeguroWeb"
                  src="/logos/gys/seguroweb.svg"
                ></img>
              </Link>
            </h1>
            {/* Escudos */}
            <div id="escudos-container">
              {/* SeguroBici */}
              <Tooltip anchorId="escudo-segurobici" content="SeguroBici" id="tooltip"></Tooltip>
                <a target="_blank" href="https://www.segurobici.com.ar/" id="escudo-segurobici">
                  <img
                    alt="Escudo del logo de SeguroBici"
                    src="/logos/gys/escudos/segurobici.svg"
                  ></img>
                </a>
              {/* SeguroMotos */}
              <Tooltip anchorId="escudo-seguromotos" content="SeguroMotos" id="tooltip"></Tooltip>
                <a target="_blank" href="https://seguromotos.com.ar/" id="escudo-seguromotos">
                  <img
                    alt="Escudo del logo de SeguroMotos"
                    src="/logos/gys/escudos/seguromotos.svg"
                  ></img>
                </a>
              {/* SeguroHogar */}
              <Tooltip anchorId="escudo-segurohogar" content="SeguroHogar" id="tooltip"></Tooltip>
                <a target="_blank" href="https://www.segurohogar.com.ar/" id="escudo-segurohogar"> 
                  <img
                    alt="Escudo del logo de SeguroHogar"
                    src="/logos/gys/escudos/segurohogar.svg"
                  ></img>
                </a>
              {/* SeguroMascotas */}
              <Tooltip anchorId="escudo-seguromascotas" content="SeguroMascotas" id="tooltip"></Tooltip>
                <a target="_blank" href="https://www.seguromascotas.com.ar/" id="escudo-seguromascotas">
                  <img
                    alt="Escudo del logo de SeguroMascotas"
                    src="/logos/gys/escudos/seguromascotas.svg"
                  ></img>
                </a>
              {/* SeguroAuto */}
              <Tooltip anchorId="escudo-seguroauto" content="SeguroAuto" id="tooltip"></Tooltip>
                <a target="_blank" href="https://auto.seguroweb.com.ar/" id="escudo-seguroauto">
                  <img
                    alt="Escudo del logo de SeguroAuto"
                    src="/logos/gys/escudos/seguroauto.svg"
                  ></img>
                </a>
              {/* SeguroComercio */}
              <Tooltip anchorId="escudo-segurocomercio" content="SeguroComercio" id="tooltip"></Tooltip>
                <a target="_blank" href="https://comercio.seguroweb.com.ar/" id="escudo-segurocomercio">
                  <img
                    alt="Escudo del logo de SeguroComercio"
                    src="/logos/gys/escudos/segurocomercio.svg"
                  ></img>
                </a>      
            </div>
          </div>
          {/* Numeros de telefono y redes Container */}
          <div id="numeros-redes-container">
            {/* Redes */}
            <div id="redes">
              {/* Instagram */}
              <a
                href="https://www.instagram.com/seguroweb/?hl=es"
                target="_blank"
              >
                <BsInstagram />
              </a>
              {/* Facebook */}
              <a href="https://www.facebook.com/SeguroWeb" target="_blank">
                <FaFacebookF />
              </a>
            </div>
            <Tooltip anchorId="boton-portal" content="Próximamente" id="tooltip"></Tooltip>
            <div className="botones-container">
              <a id="boton-portal">
                <span>
                  <TfiWorld />
                </span>
                Portal
              </a>
              {/*
              <Link className="boton-siniestros" to="/siniestros">
                <span>
                  <AiOutlineFire />
                </span>
                Denunciar siniestro
              </Link>
              */}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
