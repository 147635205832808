import { HiOutlineArrowLongRight } from "react-icons/hi2";
import Container from "./styled";

const Button = ({ onClick, children, disabled }) => {

  return (
    <Container>
      {/* Botón Contratá Container */}
        <button
          type="button"
          id="boton-contrata"
          onClick={onClick}
          disabled={disabled}
        >
          {children}
        </button>
    </Container>
  );
};

export default Button;
