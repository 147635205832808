import styled from "styled-components";
import { breakpoints } from "../../constants/media";

const Container = styled.div`

.input-label {
    font-size: 13px;
    font-family: "DM Sans";
    color: var(--negro);
    font-weight: 500;
  }
  
  // Input Container
  .input-container {
    width: 283.5px;
    margin: 30px 22px 0px 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  // Input 
  .input-container textarea {
    width: 283.5px;
    height: 180px;
    padding: 14px 20px 14px 20px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    font-weight: 400;
    color: var(--negro) !important;
    border: 1.7px solid var(--gris80);
    background-color: var(--blanco);
    -webkit-appearance: none;
    box-shadow: 0;
    transition: 0.2s all;
    border-radius: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    z-index: 58 !important;
    resize: none;

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;

      :invalid {
        color: #949494 !important;
      }
    }

    // Firefox
    [type="number"] {
      -moz-appearance: textfield !important;
    }
  }

  .input-container input::placeholder {
    color: #949494 !important;
  }

  // Input & Select Focus
  .input-container input:focus {
    border: 1.7px solid var(--gris90);
    outline: none;
    z-index: 59 !important;
  }

  // Input Error
  .input-error {
    border: 1px solid red !important;
  }

  // Input Error Text
  .input-container span {
    font-size: 12px !important;
    color: red;
  }

  .input-container .info-input {
    font-size: 12px !important;
    color: var(--gris);
  }

  .full-width { 
    width: 100%;
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Input Container y Select Container
    .input-container {
      width: 238.5px;
      margin: 30px 15px 0px 0px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Input Container, Select Container, Input Código Area, Input Telefono y Input Vencimiento
    .input-container {
      width: 216.5px;
      max-width: 216.5px;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container {
      width: 232.5px;
      max-width: 232.5px;
      margin: 30px 15px 0px 0px;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container {
      width: calc(50% - 7.5px) !important;
      max-width: calc(50% - 7.5px) !important;
    }
  }

  // 450px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile450}) {
    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container {
      width: 100% !important;
      max-width: 100% !important;
      margin: 30px 0px 0px 0px !important;
    }
  }

  // 350px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile350}) {
    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container {
      width: 100%;
      max-width: 100%;
      margin: 30px 0px 0px 0px;
    }
  }
`;

export default Container;
