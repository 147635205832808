import * as React from "react";
import Container from "./styled";

export default function Card({ image, alt, title, text, link, tag }) {
  return (
    <Container>
      {/* Card Container */}
      <section id="card-container">
        {/* Logo Texto Container */}
        <div id="logo-texto-container">
          {/* Logo */}
        <div id="logo-container">
        <img src={image} alt={alt}></img>
        </div>
          {/* Tag */}
          <p>{tag}</p>
          {/* Texto */}
          <p>{text}</p>
        </div>
        {/* Título Botón Container */}
        <div id="titulo-boton-container">
          {/* Title */}
          <p>{title}</p>
          {/* Button */}
          {(tag === "Próximamente" || tag === "Muy pronto") ? (
            <></>
          ) : (
            <a href={link} target="_blank">
              Cotizá
            </a>
          )}
        </div>
      </section>
    </Container>
  );
}
