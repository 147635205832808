import { useState } from "react";
// Estilos
import Container from "./styled";
// Librerías
import { HiOutlineArrowLongRight } from "react-icons/hi2";
// Custom Hooks
import { useForm } from "../../../hooks/useForm";
// Form
import { referidosForm } from "../../../utils/formInitialValues";
import { referidosReglas } from "../../../utils/formRules";
// Mail
import { referidosMail } from "../../../utils/mailContent";
import { sendFormMail } from "../../../utils/sendFormMail";

export default function Referidos() {
  // Custom Hooks
  const { inputValues, handleChange, handleValidation, formErrors } = useForm(
    handleSubmit,
    referidosReglas,
    referidosForm
  );
  const [buttonMessage, setButtonMessage] = useState("Enviar");

  async function handleSubmit() {
    try {
      setButtonMessage("Enviando...");
      await sendFormMail(inputValues, null, referidosMail);
      setButtonMessage("¡Mensaje enviado!");
      setTimeout(() => {
        setButtonMessage("Enviar");
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Container>
      {/* Section Max Width Container */}
      <section id="max-width-container">
        {/* Data Container */}
        <div id="data-container">
          {/* Tag */}
          <p id="tag">Referidos</p>
          {/* Título */}
          <h4>
            <strong>Aprovechá nuestro</strong>
            <br />
            <span>programa de referidos</span>
          </h4>
          {/* Texto */}
          <p id="texto">
            ¡Obtené importantes descuentos y reintegros* refiriendo a tus
            familiares y amigos para que contraten su seguro! Seas o no cliente
            de SeguroWeb.
          </p>
          {/* Aclaracion */}
          <p id="aclaracion">
            *Todos los descuentos, reintegros o beneficios obtenidos del
            programa de referidos se aplican una vez que la persona referida
            contrate su seguro.
          </p>
        </div>
        {/* Cards Container */}
        <div id="cards-container">
          {/* Título */}
          <h5>
            Referí sin límites. Por cada persona que refieras y contrate un seguro
            con SeguroWeb, sumas:
          </h5>

          {/* Texto */}
          <p>$2.000 por cada referido que contrate un seguro de bici.</p>
          {/* Botón */}

          {/* Separación */}
          <hr></hr>
          {/* Texto */}
          <p id="texto-no-cliente">
            $4.000 por cada referido que contrate un seguro de moto.
          </p>
          {/* Separación */}
          <hr></hr>

          {/* Texto */}
          <p id="texto-no-cliente">
            $7.000 por cada referido que contrate un seguro de auto.
          </p>

          {/* Separación */}
          <hr></hr>

          {/* Texto */}
          <p id="texto-no-cliente">
            $7.000 por cada referido que contrate un seguro de camión con cobertura de Responsabilidad Civil.
          </p>

          {/* Texto */}
          <p id="texto-no-cliente">
          $14.000 por cada referido que contrate un seguro de camión con coberturas más amplias que Responsabilidad Civil.
          </p>

          {/* Formulario */}
          <form>
            {/* Input Nombre */}
            <div className="input-container" id="input-nombre-container">
              <input
                type="nombre"
                id="input-nombre"
                name="nombre"
                placeholder="Nombre"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.nombre}
                className={formErrors.nombre == null ? "" : "input-error"}
              ></input>
            </div>
            {/* Input Email */}
            <div className="input-container" id="input-email-container">
              <input
                type="email"
                id="input-email"
                name="email"
                placeholder="Email"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.email}
                className={formErrors.email == null ? "" : "input-error"}
              ></input>
            </div>
            {/* Botón */}
            <button type="submit" onClick={handleValidation}>
              {buttonMessage}
              <span>
                <HiOutlineArrowLongRight />
              </span>
            </button>
          </form>
        </div>
        {/* Background Image */}
        <img
          id="background-image"
          alt=""
          src="/home/digitalizacion/curva.svg"
        ></img>
      </section>
    </Container>
  );
}
