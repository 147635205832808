import { postearArchivos } from '../services/archivos';

export const useFile = (handleChangeExternal) => {

  const onFileUpload = (event) => {
    event.preventDefault();
    event.persist();
    if (event.target.files[0]) {
      const { name } = event.target;
      handleChangeExternal(name, {
        image: event.target.files[0],
        name: event.target.files[0].name,
      })
    }
  }

  const multipleFilesUpload = (event, archivos) => {
    event.preventDefault();
    event.persist();
    if (event.target.files.length > 0) {
      const { name } = event.target;
      const archivosCargados = [...event.target.files].map(file => {
        return {
          image: file,
          name: file.name,
          size: file.size
        }
      })
      const files = [...archivos, ...archivosCargados]
      handleChangeExternal(name, files)
    }
  }

  const deleteFile = (fileName, archivos, name) => {
    if(archivos) {
      const files = [...archivos]
      const filtrarArchivo = files.filter(archivo => archivo.name !== fileName)
      handleChangeExternal(name, filtrarArchivo)
    }
    handleChangeExternal(fileName, "")
  }
  const sendFiles = async (file) => {
    let bodyFormData = new FormData();
    bodyFormData.append('fotos', file.image, file.name);
    try {
      const upFile = await postearArchivos(bodyFormData)
      const path = upFile.data;
      return path;
    } catch (error) { 
      console.log(error)
    }
  };

  return {
    onFileUpload,
    deleteFile,
    sendFiles,
    multipleFilesUpload
  }
}