import styled from "styled-components";
import { breakpoints } from "../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 60px 0px 60px 0px;

  *,
  :before,
  :after {
    box-sizing: border-box;
  }

  // Titulo
  h3 {
    width: 100%;
    margin: 0 auto;
    padding: 0px 0px 40px 0px;
    text-align: center;
    animation: opacidad 1s;
  }

  // Titulo Contrato
  #titulo-contrato {
    color: var(--verde50);
  }

  // Titulo Contrato Ícono
  #titulo-contrato span {
    margin: 0px 10px 0px 0px;
    font-size: 20px !important;
  }

  // Animaciones
  @keyframes haciendo {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(100%);
    }
  }

  @keyframes opacidad {
    from {
      opacity: 0%;
    }
    to {
      opacity: 100%;
    }
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(152, 152, 154, 0.7);
    }
    50% {
      -webkit-box-shadow: 0 0 0 10px rgba(152, 152, 154, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(152, 152, 154, 0);
    }
  }

  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(152, 152, 154, 0.7);
      box-shadow: 0 0 0 0 rgba(152, 152, 154, 0.7);
    }
    50% {
      -moz-box-shadow: 0 0 0 10px rgba(152, 152, 154, 0);
      box-shadow: 0 0 0 10px rgba(152, 152, 154, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(152, 152, 154, 0);
      box-shadow: 0 0 0 0 rgba(152, 152, 154, 0);
    }
  }

  // Pasos Container
  #pasos-container,
  #texto-container {
    width: 500px;
    height: 8px;
    margin: 0 auto;
    display: flex;
    gap: 50px;
  }

  .texto {
    flex: 1 1 0;
    width: 0;
    text-align: center;
    font-weight: 700;
  }

  .paso {
    flex: 1 1 0;
    width: 0;
    background: var(--gris90);
    border-radius: 15px;

    &#terminado {
      background: var(--verde50);
      box-shadow: 0 0 0 rgba(152, 152, 154, 0.7);
      animation: pulse 1.4s infinite;
    }

    &#hecho {
      background: var(--verde50);
      box-shadow: 0 0 0 rgba(152, 152, 154, 0.7);
      animation: pulse 1.4s infinite;
    }

    &#haciendo {
      position: relative;
      overflow: hidden;
      z-index: 0;

      &:before,
      &:after,
      span {
        display: flex;
        position: absolute;
        transform: translateX(-100%);
        height: 100%;
        width: 100%;
        border-radius: 15px;
      }

      span {
        animation: haciendo 1.5s infinite cubic-bezier(0.4, 0, 1, 1);
        background-color: var(--azul100);
        z-index: 2;
      }

      &:before {
        content: "";
        animation: haciendo 1.5s infinite cubic-bezier(0.2, 0, 1, 1);
        background-color: var(--azul40);
        z-index: 1;
      }

      &:after {
        content: "";
        animation: haciendo 1.5s infinite linear;
        background-color: var(--azul0);
        z-index: 0;
      }
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Pasos Container
    #pasos-container,
    #texto-container {
      width: 400px;
      gap: 30px;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Titulo
    h2 {
      width: 85%;
    }

    // Pasos Container
    #pasos-container,
    #texto-container {
      width: 330px;
      gap: 20px;
    }
  }

  // 414px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile414}) {
    // Pasos Container
    #pasos-container,
    #texto-container {
      width: 85%;
      gap: 5%;
    }
  }
`;

export default Container;
