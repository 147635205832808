import { useState } from "react"

export const useSwitch = () => {
    const [step, setStep] = useState(1)

    const prevStep = () => setStep(prevStep => prevStep - 1)
    
    const nextStep = (stepNumber) => {
        if(!stepNumber){
            setStep(prevStep => prevStep + 1)
        } else {
            setStep(stepNumber)
        }
    }

    return { 
        step,
        prevStep,
        nextStep
    }
}