import * as React from "react";
// Estilos
import Container from "./styled";
// Componentes
import Card from "./card/index";
import CardOtros from "./card-otros/index";
// Utils
import useWindowDimensions from "../../../utils/useWindowDimensions";

export default function Seguros() {
  const { height, width } = useWindowDimensions();

  return (
    <Container name="seguros">
      {/* Section Max Width Container */}
      <section id="max-width-container">
        {/* Tag */}
        <p id="tag">SEGUROS</p>
        {/* Titulo */}
        <h3>
          ¡<span>Cotizá ahora</span> de manera online!
        </h3>
        {/* Card Container */}
        <div id="card-container">
          {/* SeguroBici */}
          <Card
            image="/logos/gys/segurobici.svg"
            alt="Logo de SeguroBici"
            title="Estas muy cerca de asegurar tu bici, sentite libre."
            text="Bici, bici comer., monopatín, monopatín eléc.."
            link="https://www.segurobici.com.ar/"
            tag={width <= 1366 ? "Per." : "Personas"}
          />
          {/* SeguroMotos */}
          <Card
            image="/logos/gys/seguromotos.svg"
            alt="Logo de SeguroMotos"
            title="Te acompaña a donde vayas, vos elegí el camino que los une."
            text="Moto, moto comer., scooter, cuatri, ciclomotor."
            link="https://seguromotos.com.ar/"
            tag={width <= 1366 ? "Per." : "Personas"}
          />
          {/* SeguroHogar */}
          <Card
            image="/logos/gys/segurohogar.svg"
            alt="Logo de SeguroHogar"
            title="Asegurá tu casa, tu lugar."
            text="Todo tipo de viviendas en todo el país."
            link="https://www.segurohogar.com.ar/"
            tag={width <= 1366 ? "Per." : "Personas"}
          />
          {/* SeguroMascotas */}
          <Card
            image="/logos/gys/seguromascotas.svg"
            alt="Logo de SeguroMascotas"
            title="Sabemos lo importante que son, te ayudamos a cuidarlos."
            text="Perros y gatos de todas las razas."
            link="https://www.seguromascotas.com.ar/"
            tag={width <= 1366 ? "Per." : "Personas"}
          />
          {/* SeguroAuto */}
          <Card
            image="/logos/gys/seguroauto.svg"
            alt="Logo de SeguroAuto"
            title="Asegurá tu vehículo y manejá de la mejor manera."
            text="Todos los modelos de auto, camión y pickup."
            link="https://auto.seguroweb.com.ar/"
            tag={width <= 1366 ? "Per." : "Personas"}
          />
          {/* SeguroComercio */}
          <Card
            image="/logos/gys/segurocomercio.svg"
            alt="Logo de SeguroComercio"
            title="Contratá Federación Patronal, n° 1 en solvencia financiera."
            text="Todo tipo de comercios en todo el país."
            link="https://comercio.seguroweb.com.ar/"
            tag={width <= 1366 ? "Emp." : "Empresas"}
          />
          {/* Otros */}
          <CardOtros />
        </div>
      </section>
    </Container>
  );
}
