import { BsArrowLeft } from "react-icons/bs";
import Container from "./styled";

const BackButton = ({ prevStep, text }) => {
  return (
    <Container>
      {/* Botón Atras Container */}
      <div className="boton-atras-container opacidad02">
        {/* Botón Atras */}
        <button type="button" onClick={prevStep}>
          <span>
            <BsArrowLeft />
          </span>
          {text}
        </button>
      </div>
    </Container>
  );
};

export default BackButton;
