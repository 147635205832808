import styled from "styled-components";
import { breakpoints } from "../../../../constants/media";

const Container = styled.div`
  width: 377.3px;
  margin: 28px 0px 0px 0px;
  display: flex;
  justify-content: flex-start;

  // Card Container
  #card-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 24px 24px 24px 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    background-color: var(--blanco);
    border-radius: 10px;
    border: 1px solid var(--gris80);

    // Logo Texto Container
    #logo-texto-container {
      width: 100%;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      gap: 8px;

      #logo-container {
       min-width: 206px;
      }

      // Logo
      img {
        width: auto;
        max-width: 206px;
        height: 29px;
        margin: 0px 0px 0px 0px;
        pointer-events: none;
      }

      // Tag
      p:nth-child(2) {
        width: auto;
        margin: 0px 0px 0px 0px;
        padding: 5px 10px 5px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 134.7%;
        letter-spacing: 0.015em;
        text-align: center;
        color: var(--lila90);
        background-color: var(--celeste90);
        border-radius: 6px;
        border: 1px solid var(--gris80);
      }

      // Texto
      p:nth-child(3) {
        width: 100%;
        margin: 16px 0px 0px 0px;
        font-weight: 400;
        font-size: 12px;
        line-height: 134.7%;
        letter-spacing: -0.015em;
        text-align: left;
        color: var(--gris);
        pointer-events: none;
      }
    }

    // Título Botón Container
    #titulo-boton-container {
      width: 100%;
      margin: 16px 0px 0px 0px;
      padding: 16px 0px 0px 0px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      align-content: flex-start;
      border-top: 1px solid #e8f0ff;

      // Titulo
      p {
        width: 59%;
        margin: 0px 0px 0px 0px;
        min-height: 46px;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 170%;
        text-align: left;
        letter-spacing: -0.015em;
        color: var(--gris);
      }

      // Botón
      a {
        width: auto;
        margin: 0px 0px 0px 0px;
        padding: 12px 24px 12px 24px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        background-color: var(--celeste90);
        border-radius: 7px;
        border: 1px solid var(--gris80);
        text-decoration: none;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 154.7%;
        text-align: center;
        color: var(--azul60);
        transition: 0.2s all;
      }

      a:hover {
        background-color: #f3f7fe;
      }
    }
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    width: 314.6px;

    // Card Container
    #card-container {
      padding: 20px;
      // Título Botón Container
      #titulo-boton-container {
        // Titulo
        p {
          width: 100%;
        }

        // Botón
        a {
          margin: 16px 0px 0px 0px;
        }
      }
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    width: 324px;
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    width: 228px;
    max-width: 228px;
    height: 320px;

    #card-container p:nth-child(3) {
      min-height: 32px;
    }

  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    width: 100%;
    max-width: 100%;
    height: auto;

    #card-container p {
      min-height: 14px;
    }

    // Fade Container
    .fade-container {
      // Card Container
      #card-container {
        
        // Data Container
        #data-container {
          // Logo Text Container
          #logo-text-container {
            // Logo
            img {
              height: 30px;
            }
          }

          // Title Button Container
          #title-button-container {

            margin: 0px;

            // Title
            p {
              width: 60%;
            }

            // Button
            a {
              margin: 0px;
            }
          }
        }
      }
    }
  }

  // 470px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile470}) {
    height: 280px;
    // Fade Container


    .fade-container {
      // Card Container
      #card-container {
        // Data Container
        #data-container {
          // Title Button Container
          #title-button-container {
            // Title
            p {
              width: 70%;
            }
          }
        }
      }
    }
  }
`;

export default Container;
