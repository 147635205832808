import React, { useRef } from "react";
// Estilos
import Container from "./styled";
// Librerías
import Marquee from "react-fast-marquee";
// Utils
import useWindowDimensions from "../../../../utils/useWindowDimensions";

export default function LogosAbajo() {
  const { height, width } = useWindowDimensions();

  return (
    <Container>
      {/* Section Max Width Container */}
      <section id="max-width-container">
        {/* Logo Slider */}
        <div id="logo-slider">
          <Marquee
            gradient={width <= 850 ? false : true}
            loop={0}
            gradientColor={[247, 249, 254]}
            speed={17}
            direction="right"
          >
            {/* Logo */}
            <div className="logo" id="nacion-seguros">
              <img
                alt="Nación Seguros Logo"
                src="/logos/aseguradoras/nacion-seguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="provincia-seguros">
              <img
                alt="Prudencia Seguros Logo"
                src="/logos/aseguradoras/provincia-seguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="colon-seguros">
              <img
                alt="Colón Seguros Logo"
                src="/logos/aseguradoras/colon-seguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="rus-seguros">
              <img
                alt="RUS Seguros Logo"
                src="/logos/aseguradoras/rus-seguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="hdi-seguros">
              <img
                alt="RUS Seguros Logo"
                src="/logos/aseguradoras/hdi-seguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="federacion-patronal-seguros">
              <img
                alt="Logo de Federación Patronal Seguros"
                src="/logos/aseguradoras/federacion-patronal-seguros.png"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="nacion-seguros">
              <img
                alt="Nación Seguros Logo"
                src="/logos/aseguradoras/nacion-seguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="provincia-seguros">
              <img
                alt="Prudencia Seguros Logo"
                src="/logos/aseguradoras/provincia-seguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="colon-seguros">
              <img
                alt="Colón Seguros Logo"
                src="/logos/aseguradoras/colon-seguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="rus-seguros">
              <img
                alt="RUS Seguros Logo"
                src="/logos/aseguradoras/rus-seguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="hdi-seguros">
              <img
                alt="RUS Seguros Logo"
                src="/logos/aseguradoras/hdi-seguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="federacion-patronal-seguros">
              <img
                alt="Logo de Federación Patronal Seguros"
                src="/logos/aseguradoras/federacion-patronal-seguros.png"
              ></img>
            </div>
          </Marquee>
        </div>
      </section>
    </Container>
  );
}
