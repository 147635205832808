import styled from "styled-components";
import { breakpoints } from "../../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--blanco);

  // Max Width Container
  #max-width-container {
    width: 100%;
    max-width: 1270px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
  }

  // Card Container
  #card-container {
    width: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    background-color: var(--celeste90);
    border: 1px solid var(--gris80);
    border-radius: 10px;
    position: relative;
    overflow: hidden;

    // Data Container
    #data-container {
      width: calc(40% - 136px);
      padding: 64px 32px 64px 64px;
      margin: 0px 40px 0px 0px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
      view-timeline-name: --moviendo;
      view-timeline-axis: block;
      animation: linear mover both;
      animation-timeline: --moviendo;
      animation-range: entry 35% cover 45%;
      z-index: 51;

      // Tag Container
      #tag-container {
        width: 100%;
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;

        // Tag
        div {
          width: auto;
          padding: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          align-content: center;
        }

        // Tag Text
        p {
          width: auto;
          margin: 0px 0px 0px 0px;
          font-weight: 500;
          font-size: 13px;
          text-align: center;
          color: var(--verde90);
          line-height: 154.7%;
          text-transform: uppercase;
          letter-spacing: 2px;
        }
      }

      // Title
      h3 {
        width: 100%;
        margin: 28px 0px 0px 0px;
        font-weight: 500;
        font-size: 25px;
        text-align: left;
        letter-spacing: -0.015em;
        line-height: 42px;

        // Strong
        strong {
          font-size: 23px;
          font-weight: 500;
        }

        // Span
        span {
          font-weight: 500;
          color: var(--azul100);
        }
      }

      // Text
      p {
        width: 90%;
        margin: 28px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
        flex-wrap: wrap;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 180%;
        text-align: left;
        letter-spacing: -0.015em;
        color: var(--lila90);
        z-index: 51;
      }
    }

    // Image Container
    #image-container {
      width: 52.27%;
      padding: 64px 0px 0px 0px;
      margin: 0px 0px 0px 0px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      align-content: flex-end;
      view-timeline-name: --mostrando;
      view-timeline-axis: block;
      animation: linear mostrar both;
      animation-timeline: --mostrando;
      animation-range: entry 35% cover 37%;

      // Image
      img {
        width: 100%;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        object-fit: cover;
        pointer-events: none;
        user-select: none;
      }
    }

    // Curva Image
    #curva-image {
      width: 260px;
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0.5;
      pointer-events: none;
      user-select: none;
      z-index: 50;
      transform: scaleX(-1) rotate(90deg);
    }
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    // Max Width Container
    #max-width-container {
      max-width: 1130px;
    }

    // Card Container
    #card-container {
      // Data Container
      #data-container {
        width: calc(40% - 80px);
      }
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    #max-width-container {
      max-width: 680px;
    }

    // Card Container
    #card-container {
      flex-direction: column-reverse;

      // Data Container
      #data-container {
        width: calc(100% - 128px);
        padding: 64px 64px 64px 64px;
        margin: 0px 0px 0px 0px;

        // Texto
        p {
          width: 100%;
        }
      }

      // Image Container
      #image-container {
        width: 91.7%;
        padding: 8px 0px 0px 0px;
      }
    }

    // Curva Image
    #curva-image {
      top: 0;
      left: 0;
      right: unset;
      transform: scaleX(-1) rotate(180deg) !important;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Max Width Container
    #max-width-container {
      max-width: 480px;
    }

    // Card Container
    #card-container {
      flex-direction: row;

      // Image Container
      #image-container {
        display: none;
      }
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Max Width Container
    #max-width-container {
      max-width: 400px;
    }

    // Card Container
    #card-container {
      // Data Container
      #data-container {
        width: calc(100% - 96px);
        padding: 48px 48px 48px 48px;
      }
    }
  }

  // 525px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile525}) {
    // Max Width Container
    #max-width-container {
      max-width: 85%;
    }
  }
`;

export default Container;
