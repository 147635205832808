import styled from "styled-components";
import { breakpoints } from "../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 30px 0px 0px 0px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;

  .input-label {
    font-size: 13px;
    font-family: "DM Sans";
    margin-bottom: 10px;
  }

  // Input File Container
  #input-file-container {
    width: 100%;
    height: 180px;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    border-radius: 10px;

    // Carga Container
    #carga-container {
      width: 100%;
      height: 100%;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      position: relative;

      // Imagen Escudo
      img {
        width: 19px;
        position: absolute;
        top: 22px;
        right: 24px;
        pointer-events: none;
        user-select: none;
      }

      // Label
      label {
        width: 100%;
        height: 100%;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        background-color: var(--blanco);
        border: 1.7px solid var(--gris80);
        border-radius: 10px;
        cursor: pointer;
        transition: 0.2s all;

        :hover {
          background-color: #f6f6f6;
        }

        // Ícono
        span {
          width: 100%;
          margin: 0px 0px 0px 0px;
          padding: 0px 0px 0px 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          align-content: center;
          color: var(--verde50);
          font-size: 24px;
          text-align: center;
        }

        // Texto
        p {
          width: 100%;
          margin: 16px 0px 0px 0px;
          padding: 0px 0px 0px 0px;
          font-family: "DM Sans";
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          font-weight: 500;
          color: var(--gris);
          text-align: center;
          line-height: 13px;
        }

        // Texto formato
        p:nth-child(3) {
          margin: 10px 0px 0px 0px;
          padding: 0px 0px 0px 0px;
          font-size: 12px;
          font-weight: 400;
        }
      }

      // Input
      input {
        display: none;
      }
    }
  }

  // Archivo Container
  #archivo-container {
    width: 100%;
    height: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: var(--blanco);
    border: 1.7px solid var(--grisClaro);
    border-radius: 10px 10px 0px 0px;
    position: relative;

    // Imagen Escudo
    img {
      width: 19px;
      position: absolute;
      top: 22px;
      right: 24px;
      pointer-events: none;
      user-select: none;
    }

    // Ícono
    span {
      width: 100%;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      color: var(--lila90);
      font-size: 30px;
      text-align: center;
    }

    // Texto
    p {
      width: 80%;
      margin: 8px 10% 0px 10%;
      padding: 0px 0px 0px 0px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      font-weight: 500;
      color: var(--gris);
      text-align: center;
      line-height: 19px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    // Botón
    a {
      width: auto;
      margin: 10px 4px 0px 4px;
      padding: 0px 0px 0px 0px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      font-weight: 400;
      color: var(--gris);
      text-align: center;
      line-height: 13px;
      transition: 0.1s all;

      // Hover
      :hover {
        opacity: 0.7;
      }
    }
  }

  // Requisitos Container
  #requisitos-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: var(--blanco);
    border: 1.7px solid var(--grisClaro);
    border-radius: 0px 0px 10px 10px;

    // Título
    h4 {
      width: 100%;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      font-weight: 500;
      color: var(--gris);
      text-align: left;
      line-height: 20px;

      // Ícono
      span {
        margin: 3px 4px 0px 0px;
        color: var(--naranja100);
      }
    }

    // Texto y Botón Ejemplo
    p,
    #boton-ejemplo {
      width: 100%;
      margin: 8px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      font-weight: 400;
      color: var(--gris);
      text-align: left;
      line-height: 20px;

      // Botón
      a {
        margin: 0px 0px 0px 4px;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        font-weight: 400;
        color: var(--gris);
        text-align: left;
        line-height: 20px;
        text-decoration: underline;
        transition: 0.2s all;

        :hover {
          opacity: 0.7;
        }
      }
    }

    #boton-ejemplo {
      color: var(--naranja100);
    }

    // Texto Peso
    #texto-peso {
      width: 50% !important;
      margin: 24px 0px 0px 0px !important;
      line-height: 9px !important;
    }

    // Botón Ejemplo
    #boton-ejemplo {
      width: 50% !important;
      margin: 22px 0px 0px 0px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      text-align: right;
      line-height: 9px;
      transition: 0.2s all;

      // Ícono
      span {
        margin: 0px 4px 0px 0px;
        font-size: 13px;
      }

      // Hover
      :hover {
        opacity: 0.7;
      }
    }
  }

  // Input Error Text
  .error-input {
    font-size: 12px;
    color: red;
    margin-top: 10px;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    width: 100%;
  }

  .archivo-cargado-container {
    background-color: var(--blanco);
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    margin-top: 20px;
    border-radius: 6px;
    box-sizing: border-box;
    display: flex;
    gap: 10px;
    align-items: center;
    font-family: "DM Sans";
    padding: 16px 20px;
    justify-content: space-between;
    width: 100%;
  }

  .archivo-cargado-informacion-container {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .archivo-cargado-imagen {
    display: flex;
    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 6px;
      border: 1px solid var(--grisClaro);
    }
  }

  .archivo-cargado-pdf {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 6px;
      border: 1px solid var(--gris90);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: var(--lila90);
  }

  .archivo-cargado-informacion {
    display: flex;
    flex-direction: column;
  }

  .archivo-cargado-nombre {
    padding: 0px;
    font-family: "DM Sans";
    font-style: normal;
    font-size: 13px;
    font-weight: 500;
    color: var(--negro);
    line-height: 19px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 150px;
  }

  .archivo-cargado-peso {
    font-size: 12px;
    color: var(--gris);
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    text-align: left;
    letter-spacing: -0.015em;
  }

  .archivo-cargado-eliminar {
    cursor: pointer;
    span {
      font-size: 20px;
    }
  }

    // 1710px Desktop ------------------------------------------------------------------------------------
    @media (max-width: ${breakpoints.desktop1710}) {
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }
  }

  // 450px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile450}) {
    .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }
  }

  // 350px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile350}) {
    .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export default Container;
