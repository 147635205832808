import styled from "styled-components";
import { breakpoints } from "../../constants/media";

const Container = styled.div`
  width: 100%;
  background-color: var(--blanco);

  .poliza {
    border: 1px solid var(--gris90);
    background-color: var(--blanco);
    box-sizing: border-box;
    padding: 20px 0;
    margin-top: 20px;
    border-radius: 6px;
    box-sizing: border-box;

    .divider {
      height: 1px;
      width: 100%;
      opacity: 0.2;
      margin: 20px 0;
    }

    span {
      padding: 0px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 180%;
      text-align: left;
      letter-spacing: -0.015em;
      color: var(--gris);
      -webkit-font-smoothing: subpixel-antialiased;
    }
  }

  .poliza-header h3,
  .poliza-header span {
    margin: 0;
    padding: 0;
  }

  .capitalize-nombre {
    text-transform: capitalize;
  }
  
  .poliza-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    h3 {
      font-size: 16px;
      color: var(--gris);
      font-weight: 600;
    }

    img {
      width: 120px;
    }
  }

  .cont {
    display: flex;
  }

  .poliza-datos {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 0 20px;
  }

  .vigencia-container {
    width: 50%;
    padding: 0 20px;
    //border: 1px solid var(--gris90);
  }

  .poliza-dato {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  button {
    width: 100%;
    padding: 14px 26px 14px 26px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: var(--azul100);
    border-radius: 8px;
    border: 0;
    text-decoration: none;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 154.7%;
    text-align: center;
    color: var(--blanco);
    transition: 0.2s all;
  }

  .asegurado {
    font-size: 16px !important;
    font-weight: 600 !important;
    //color: var(--negro) !important;
  }

  .fecha {
    //color: var(--negro) !important;
    font-weight: 600 !important;
  }

`;

export default Container;
