import { useState } from "react";

export const useLoading = () => {
  const [loading, setLoading] = useState(false);

  const initiateLoading = () => {
    setLoading(true)
  }

  return {
    loading,
    initiateLoading
  }
};
