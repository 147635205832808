import React from "react";
// Estilos
import Container from "./styled";

export default function CardEstrellas() {
  return (
    <Container>
      {/* Título */}
      <h3>
        Conocé la opinón de <span>nuestros clientes</span>
      </h3>
      {/* Estrellas Container */}
      <div id="estrellas-container">
        <img alt="Estrella" src="/home/introduccion/estrella.svg"></img>
        <img alt="Estrella" src="/home/introduccion/estrella.svg"></img>
        <img alt="Estrella" src="/home/introduccion/estrella.svg"></img>
        <img alt="Estrella" src="/home/introduccion/estrella.svg"></img>
        <img alt="Estrella" src="/home/introduccion/estrellaVacia.svg"></img>
      </div>
      <div id="data-container">
        <div>
          <h3>4.7</h3>
          <h4>
            <span>estrellas</span>, en reseñas reales<br></br>valoradas por
            clientes en Google
          </h4>
        </div>
        <p id="texto">
          SeguroWeb con un puntaje de 4.7 estrellas y más de 1000 reseñas es
          valorada auténticamente por muchos de sus clientes.
        </p>
      </div>
    </Container>
  );
}
