import Container from "./styled";
import { logos } from "../../constants/logos-companias";
import dayjs from 'dayjs'
const Poliza = ({ poliza, nextStep, handlePoliza }) => {
  const logo = logos.find(
    (logo) => logo.nombre.toLowerCase() === poliza.compania.toLowerCase()
  );

  const moveForward = () => {
    handlePoliza(poliza)
    nextStep()
  }

  return (
    <Container>
      <div className="poliza">
        <div className="poliza-header">
          <div className="poliza-header-titulo">
            <span>Póliza número</span>
            <h3>{poliza.poliza}</h3>
          </div>
          <img src={logo?.url} alt={`Logo de la compañia ${poliza.compania}`} />
        </div>
        <hr className="divider" />
        <div className="poliza-datos">
          <span className="capitalize-nombre asegurado"> 
            {poliza.nombre.toLowerCase()} {poliza.apellido.toLowerCase()}
          </span>
          <span className="capitalize-nombre">{poliza.compania.toLowerCase()}</span>
          <span className="capitalize-nombre"> 
            Ramo: {poliza.ramo.toLowerCase()} - Sección: {poliza.seccion.toLowerCase()}
          </span>
        </div>
        <hr className="divider" />
        <div className="cont">
          <div className="vigencia-container">
            <span className="capitalize-nombre"> 
                Inicio vigencia: <span className="fecha">{dayjs(poliza.inicio_vig).format("DD/MM/YYYY")}</span>
              </span>
          </div>
          <div className="vigencia-container">
            <span className="capitalize-nombre"> 
                Fin vigencia: <span className="fecha">{dayjs(poliza.fin_vig).format("DD/MM/YYYY")}</span>
              </span>
          </div>
        </div>
        {nextStep ? 
          <>
            <hr className="divider" />
            <div className="poliza-datos">

            <button type="button" onClick={moveForward}>
              Seleccionar póliza
            </button>
            </div>
          </>
        : null}
      </div>
    </Container>
  );
};

export default Poliza;
