import React from "react";
// Estilos
import Container from "./styled";
// Librerías
import ReCAPTCHA from "react-google-recaptcha";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { HiOutlineArrowLongLeft } from "react-icons/hi2";
// Custom Hooks
import { useForm } from "../../../hooks/useForm";
import { useFile } from "../../../hooks/useFile";
import { useLoading } from "../../../hooks/useLoading";
import { useScrollTop } from "../../../hooks/useScrollTop";
// Form
import { formaParteReglas } from "../../../utils/formRules";
import { formaParteForm } from "../../../utils/formInitialValues";
// Mail
import { formaParteMail } from "../../../utils/mailContent";
import { sendFormMail } from "../../../utils/sendFormMail";
// Componentes
import Aside from "../aside";
import InputFile from "./inputFile";

export default function Formulario({ pasoSiguiente }) {
  // Custom Hooks
  const {
    inputValues,
    handleChange,
    handleValidation,
    formErrors,
    handleChangeExternal,
  } = useForm(handleSubmit, formaParteReglas, formaParteForm);
  const { sendFiles } = useFile(handleChangeExternal);
  const { loading, initiateLoading } = useLoading();

  useScrollTop();

  async function handleSubmit() {
    try {
      initiateLoading();
      const filePath = await sendFiles(inputValues.cv);
      if (filePath) {
        await sendFormMail(
          inputValues,
          pasoSiguiente,
          formaParteMail,
          filePath
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  console.log(formErrors)

  return (
    <Container>
      {/* Max Width Container */}
      <div id="max-width-container">
        {/* Formulario Container */}
        <div id="formulario-container">
          <div id="titulo-container">
            {/* Botón Inicio */}
            <a id="boton-inicio" href="/">
              <span>
                <HiOutlineArrowLongLeft />
              </span>{" "}
              Inicio
            </a>
            {/* Título */}
            <h3>
              Trabajá en <span>SeguroWeb</span>
            </h3>
            {/* Tag */}
            <p id="tag">Recursos humanos</p>
            {/* Texto */}
            <p id="texto">
              ¡Formá parte de nuestra empresa! ¿Tenés ganas de trabajar con
              nosotros? Completá el siguiente formulario y envianos un mensaje
              con tus motivaciones, pronto nos comunicaremos para charlar con
              vos.
            </p>
          </div>
          {/* Formulario */}
          <form>
            {/* Input Nombre */}
            <div className="input-container" id="input-nombre-container">
              <input
                type="text"
                id="input-nombre"
                name="nombre"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.nombre}
                className={formErrors.nombre == null ? "" : "input-error"}
                placeholder="Nombre"
              ></input>
            </div>
            {/* Input Apellido */}
            <div className="input-container" id="input-apellido-container">
              <input
                type="text"
                id="input-apellido"
                name="apellido"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.apellido}
                className={formErrors.apellido == null ? "" : "input-error"}
                placeholder="Apellido"
              ></input>
            </div>
            {/* Input Email */}
            <div className="input-container" id="input-email-container">
              <input
                type="email"
                id="input-email"
                name="email"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.email}
                className={formErrors.email == null ? "" : "input-error"}
                placeholder="Email"
              ></input>
            </div>
            {/* Input Teléfono */}
            <div className="input-container" id="input-telefono-container">
              <input
                type="number"
                id="input-telefono"
                name="telefono"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.telefono}
                className={formErrors.telefono == null ? "" : "input-error"}
                placeholder="Teléfono"
              ></input>
            </div>
            {/* Input LinkedIn */}
            <div className="input-container" id="input-linkedin-container">
              <input
                type="text"
                id="input-linkedin"
                name="linkedin"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.linkedin}
                className={formErrors.linkedin == null ? "" : "input-error"}
                placeholder="Linkedin"
              ></input>
            </div>
            {/* Puesto */}
            <div className="input-container" id="input-puesto-container">
              <input
                type="text"
                id="input-puesto"
                name="puesto"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.puesto}
                className={formErrors.puesto == null ? "" : "input-error"}
                placeholder="Puesto"
              ></input>
            </div>
            {/* Textarea Mensaje */}
            <div className="input-container" id="textarea-mensaje-container">
              <textarea
                type="text"
                id="input-mensaje"
                name="mensaje"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.mensaje ? inputValues.mensaje : ""}
                className={formErrors.mensaje == null ? "" : "input-error"}
                placeholder="Contanos tus motivaciones"
              ></textarea>
            </div>
            {/* InputFile */}
            <InputFile
              handleChangeExternal={handleChangeExternal}
              archivo={inputValues.cv}
              error={formErrors.cv}
            ></InputFile>
            {/* Captcha Container 
            <div id="captcha-container">
              <ReCAPTCHA
                sitekey="6LfvW5YdAAAAADsIry4KRJS5YkcRb7L20iJbYLzQ"
                size="compact"
                hl="es"
                theme="light"
                onChange={(value) => handleChangeExternal("captcha", value)}
              />
              {formErrors.captcha && <span>{formErrors.captcha}</span>}
            </div>
            */}
            {/* Botón Container */}
            <div id="boton-container">
              {/* Botón */}
              <button
                type="submit"
                onClick={handleValidation}
                disabled={loading}
              >
                {loading ? "Enviando..." : "Enviar"}
                <span>
                  <HiOutlineArrowLongRight />
                </span>
              </button>
            </div>
          </form>
        </div>
        {/* Separación */}
        <div id="separacion">
          <hr></hr>
        </div>
        <Aside></Aside>
      </div>
    </Container>
  );
}
