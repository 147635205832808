import axios from "axios"
import url from "../utils/url";

export const obtenerProvincias = async () => {
    const provincias = await axios.get(`${url}/api/provincias`);
    const provinciasList = provincias.data.map((provincia, index) => {
        return {
            'label': provincia.provincia_nombre,
            'value': provincia.provincia_nombre,
            'key': index + 1
        }
    })
    return provinciasList
}

export const obtenerLocalidades = async (provincia) => {
    const localidades = await axios.get(`${url}/api/localidades?provincia=${provincia}`);
    const localidadesList = localidades.data.map((localidad, index) => {
        return {
            'label': localidad.localidad_nombre,
            'value': localidad.localidad_nombre,
            'key': index + 1
        }
    })
    return localidadesList
}

