import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 48px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  border-top: 1px solid var(--gris80);
  // Puntos de fondo
  background: linear-gradient(
        90deg,
        var(--celeste90) calc(22px - 1.4px),
        transparent 1%
      )
      center / 22px 22px,
    linear-gradient(var(--celeste90) calc(22px - 1.4px), transparent 1%) center /
      22px 22px,
    #98a5be;

  // Max Width Container
  #max-width-container {
    width: 100%;
    max-width: 1188px;
    padding: 72px 0px 88px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
  }

  // Tag
  #tag {
    width: 100%;
    margin: 0px 0px 0px 0px;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    color: var(--verde90);
    line-height: 154.7%;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  // Titulo
  h3 {
    width: 100%;
    margin: 28px 0px 0px 0px;
    font-weight: 500;
    font-size: 25px;
    text-align: center;
    letter-spacing: -0.015em;
    line-height: 42px;

    // Span
    span {
      font-weight: 500;
      color: var(--azul100);
    }
  }

  // Card Container
  #card-container {
    width: 100%;
    margin: 24px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    // Max Width Container
    #max-width-container {
      width: 1000px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    #max-width-container {
      width: 680px;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Max Width Container
    #max-width-container {
      max-width: 480px;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Max Width Container
    #max-width-container {
      width: 400px;
    }
  }

  // 470px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile470}) {
    // Max Width Container
    #max-width-container {
      width: 332px;
    }
  }

  // 370px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile370}) {
    // Max Width Container
    #max-width-container {
      width: 270px;
    }
  }
`;

export default Container;
