import React, { useRef } from "react";
// Estilos
import Container from "./styled";
// Librerías
import Marquee from "react-fast-marquee";
// Utils
import useWindowDimensions from "../../../../utils/useWindowDimensions";

export default function LogosArriba() {
  const { height, width } = useWindowDimensions();

  return (
    <Container>
      {/* Section Max Width Container */}
      <section id="max-width-container">
        {/* Logo Slider */}
        <div id="logo-slider">
          <Marquee
            gradient={width <= 850 ? false : true}
            loop={0}
            gradientColor={[247, 249, 254]}
            speed={17}
          >
            {/* Logo */}
            <div className="logo" id="atm-seguros">
              <img
                alt="Logo de ATM Seguros"
                src="/logos/aseguradoras/atm-seguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="lasegunda-seguros">
              <img
                alt="Logo de LaSegunda Seguros"
                src="/logos/aseguradoras/lasegunda-seguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="parana-seguros">
              <img
                alt="Logo de Paraná Seguros"
                src="/logos/aseguradoras/parana-seguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="experta-seguros">
              <img
                alt="Logo de Experta Seguros"
                src="/logos/aseguradoras/experta-seguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="sancor-seguros">
              <img
                alt="Logo de Sancor Seguros"
                src="/logos/aseguradoras/sancor-seguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="prudencia-seguros">
              <img
                alt="Logo de Prudencia Seguros"
                src="/logos/aseguradoras/prudencia-seguros.png"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="atm-seguros">
              <img
                alt="Logo de ATM Seguros"
                src="/logos/aseguradoras/atm-seguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="lasegunda-seguros">
              <img
                alt="Logo de LaSegunda Seguros"
                src="/logos/aseguradoras/lasegunda-seguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="parana-seguros">
              <img
                alt="Logo de Paraná Seguros"
                src="/logos/aseguradoras/parana-seguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="experta-seguros">
              <img
                alt="Logo de Experta Seguros"
                src="/logos/aseguradoras/experta-seguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="sancor-seguros">
              <img
                alt="Logo de Sancor Seguros"
                src="/logos/aseguradoras/sancor-seguros.svg"
              ></img>
            </div>
            {/* Logo */}
            <div className="logo" id="prudencia-seguros">
              <img
                alt="Logo de Prudencia Seguros"
                src="/logos/aseguradoras/prudencia-seguros.png"
              ></img>
            </div>
          </Marquee>
        </div>
      </section>
    </Container>
  );
}
