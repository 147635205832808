import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.nav`
  height: 100vh !important;
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f9fafc;
  border-right: 1px var(--grisClaro) solid;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  z-index: 111 !important;

  // Botón Cerrar
  button {
    width: 35px;
    height: 34px;
    position: absolute;
    left: 0px;
    padding: 0px 0px 0px 0px !important;
    margin: 13px 0px 0px 32px !important;
    background-color: transparent;
    border: 0;
    color: #a5adbb;
    font-size: 31px;
    cursor: pointer;
    transition: 0.2s all;
  }

  // Botón Cerrar Hover
  button:hover {
    color: var(--negro);
  }

  // Botones Container
  #botones-container {
    width: auto;
    height: 100vh;
    padding: 0px 0px 0px 0px !important;
    margin: 64px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Linea
  hr {
    width: 100%;
    margin: 0px 0px 0px 0px;
    border-color: var(--grisClaro) 1px solid;
    opacity: 0.15;
  }

  // Nav Lista Items
  li {
    width: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    list-style-type: none;
  }

  // Nav Lista Items Botones
  li a {
    padding: 16px 48px 16px 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 13px !important;
    font-weight: 500;
    color: var(--gris);
    text-decoration: none;
    transition: 0.2s all;
    cursor: pointer;
  }

  // Nav Lista Items Botones Hover
  li:hover > a {
    color: var(--blue);
  }

  // Nav Lista Items Botones Imagen Escudo
  li a img {
    width: 24px;
    margin: 0px 10px 2px 0px;
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    display: flex;
  }
`;

export default Container;
