import React from 'react'
import Container from './styled'

const InputTextarea = ({ label, name, onChange, error, value, placeholder, fullWidth, maxLength }) => {
  return (
    <Container>
        <div className={`input-container ${fullWidth && 'full-width'}`} id="textarea-mensaje-container"  >
        <label
            for="input-mensaje"
            className="input-label"
          >
            {label}
          </label>
          <textarea
            id="input-mensaje"
            name={name}
            onChange={onChange}
            onFocus={onChange}
            onBlur={onChange}
            value={value}
            className={error == null ? "" : "input-error"}
            placeholder={placeholder}
            maxLength={maxLength}
          ></textarea>
          {error && <span>{error}</span>}
        </div>
    </Container>
  )
}

export default InputTextarea