import InnerContainer from "../../components/InnerContainer"
import DenunciaStep from "../../components/SiniestrosSteps/DenunciaStep"
import PolizaStep from "../../components/SiniestrosSteps/PolizaStep"
import ConfirmacionStep from "../../components/SiniestrosSteps/ConfirmacionForm"
import WizardSiniestros from "../../components/WizardSiniestros"
import { SiniestrosProvider } from "../../context/WizardSiniestrosProvider"
import Container from "./styled"

const SiniestrosPage = () => {
  return (
    <Container>
        <InnerContainer center={true} >
            <SiniestrosProvider>
                <WizardSiniestros>
                    <PolizaStep />
                    <DenunciaStep />
                    <ConfirmacionStep />
                </WizardSiniestros>
            </SiniestrosProvider>
        </InnerContainer>
    </Container>
  )
}

export default SiniestrosPage