import React, { useEffect } from "react";
// Librerías
import styled from "styled-components";
import { motion } from "framer-motion";
// Components
import Presentacion from "../components/inicio/presentacion";
import Introduccion from "../components/inicio/introduccion";
import Estrellas from "../components/inicio/estrellas";
import Seguros from "../components/inicio/seguros";
import Pasos from "../components/inicio/pasos";
import Referidos from "../components/inicio/referidos";
import Beneficios from "../components/inicio/beneficios";
import Contacto from "../components/inicio/contacto";
import Faqs from "../components/inicio/faqs";
import BotonFlotante from "../components/inicio/boton-flotante";
import {useSEO} from "../hooks/useSEO";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--blanco);

  // Motion Div
  .motion-div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
  }
`;

export default function InicioPage() {

  useSEO({ 
    title: "", 
    description: "¡Cotizá tu seguro online! Robo total desde 1990, terceros con granizo desde 2001, todo riesgo desde 2014 ¡conocé todas las opciones de cobertura!" 
  });

  // Scroll ni bien carga la página
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <Container>

      {/* Boton Flotante WhatsApp*/}
      <BotonFlotante />

      <motion.div
        className="motion-div"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1, transition: { duration: 0.3 } }}
      >
        {/* Presentacion */}
        <Presentacion></Presentacion>
        {/* Introduccion */}
        <Introduccion></Introduccion>
        {/* Seguros */}
        <Seguros></Seguros>
        {/* Pasos */}
        <Pasos></Pasos>
        {/* Referidos */}
        <Referidos></Referidos>
        {/* Beneficios */}
        <Beneficios></Beneficios>
        {/* Contacto */}
        <Contacto></Contacto>
        {/* Faqs */}
        <Faqs></Faqs>
      </motion.div>
    </Container>
  );
}
