import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 0px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--celeste90);
  border-top: 1px solid var(--gris80);

  // Max Width Container
  section {
    width: 1140px;
    margin: 0px 0px 0px 0px;
    padding: 96px 0px 96px 0px;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    position: relative;
    overflow: hidden;

    // Background Image
    #background-image {
      width: 1000px;
      position: absolute;
      top: 0;
      left: 40px;
      z-index: 80;
      opacity: 0.5;
      pointer-events: none;
      transform: scale(-1);
    }
  }

  // Data Container
  #data-container {
    width: calc(50% - 20px);
    height: 100%;
    margin: 0px 20px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    z-index: 98 !important;

    // Tag
    #tag {
      width: auto;
      margin: 0px 0px 0px 0px;
      font-weight: 500;
      font-size: 13px;
      text-align: center;
      color: var(--verde90);
      line-height: 154.7%;
      text-transform: uppercase;
      letter-spacing: 2px;
    }

    // Título
    h4 {
      width: 90%;
      margin: 28px 0px 0px 0px;
      font-weight: 500;
      font-size: 30px;
      text-align: left;
      letter-spacing: -0.015em;
      line-height: 46px;

      // Strong
      strong {
        font-size: 25px;
        font-weight: 500;
      }

      // Span
      span {
        font-weight: 500;
        color: var(--azul100);
      }
    }

    // Texto
    #texto {
      width: 440px;
      margin: 28px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
      flex-wrap: wrap;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 190%;
      text-align: left;
      letter-spacing: -0.015em;
      color: var(--lila90);
    }

    // Aclaración
    #aclaracion {
      width: 440px;
      margin: 55px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
      flex-wrap: wrap;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 160%;
      text-align: left;
      letter-spacing: -0.015em;
      color: var(--lila90);
    }
  }

  // Cards Container
  #cards-container {
    width: calc(50% - 20px);
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;

    // Título
    h5 {
      width: 100%;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      font-weight: 500;
      font-size: 17px;
      text-align: left;
      line-height: 145.5%;
      letter-spacing: -0.015em;
      color: var(--negro);
    }

    // Texto
    p {
      width: 80%;
      margin: 16px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
      flex-wrap: wrap;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 190%;
      text-align: left;
      letter-spacing: -0.015em;
      color: var(--lila90);
    }

    // Separación
    hr {
      width: 100%;
      margin: 10px 0px 10px 0px;
      border: 0.5px solid #e8f0ff;
    }

    // Botón
    a {
      width: auto;
      margin: 16px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      align-content: center;
      font-weight: 500;
      font-size: 13px;
      line-height: 180%;
      color: var(--verde50);
      transition: 0.2s all;
      cursor: pointer;

      // Span
      span {
        margin: 5px 0px 0px 4px;
        font-size: 20px;
        transition: 0.2s all;
      }

      :hover {
        color: var(--verde90);

        // Span
        span {
          color: var(--verde90);
          transform: translate(8px, 0px);
        }
      }
    }

    // Formulario
    form {
      width: 100%;
      margin: 24px 0px 0px 0px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      justify-content: flex-end;

      // Input Container
      .input-container {
        width: calc(50% - 12px);
        margin: 0px 24px 0px 0px;
      }

      // Input Mail Container
      #input-email-container {
        margin: 0px 0px 0px 0px;
      }

      // Input
      .input-container input {
        width: 100%;
        padding: 14px 20px 14px 20px;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        font-weight: 400;
        color: var(--negro);
        border: 1.7px solid var(--grisClaro2);
        background-color: var(--blanco);
        -webkit-appearance: none;
        box-shadow: 0;
        transition: 0.2s all;
        border-radius: 8px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        text-rendering: optimizeLegibility;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        z-index: 58 !important;

        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      // Input Error
      .input-error {
        border: 1px solid red !important;
      }

      // Input and Textarea Focus
      .input-container input:focus {
        border: 1.7px solid var(--grisClaro);
        outline: none;
        z-index: 59 !important;
      }

      // Botón
      button {
        width: auto;
        margin: 30px 0px 0px 0px;
        padding: 14px 26px 14px 26px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        background-color: var(--azul100);
        border-radius: 8px;
        border: 0;
        text-decoration: none;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 154.7%;
        text-align: center;
        color: var(--blanco);
        transition: 0.2s all;

        // Ícono
        span {
          margin: 1px 0px 0px 8px;
          font-size: 20px;
          transition: 0.2s all;
        }

        :hover {
          background-color: var(--azul40);

          span {
            transform: translate(8px, 0px);
          }
        }
      }
    }
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    // Max Width Container
    section {
      width: 1000px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    position: relative;
    overflow: hidden;

    // Max Width Container
    section {
      width: 680px;
      justify-content: center;
      position: unset;

      // Background Image
      #background-image {
        width: 500px;
        top: 0;
        left: unset !important;
        right: 0 !important;
        z-index: 80;
      }
    }

    // Data Container
    #data-container {
      width: 100%;
      height: auto;
      margin: 0px 0px 0px 0px;
      justify-content: center;

      // Título
      h4 {
        text-align: center;
      }

      // Texto
      #texto {
        text-align: center;
      }

      // Aclaración
      #aclaracion {
        margin: 24px 0px 0px 0px;
        text-align: center;
      }
    }

    // Cards Container
    #cards-container {
      width: 75%;
      margin: 56px 0px 0px 0px;
      justify-content: center;
      z-index: 98 !important;

      // Texto no cliente
      #texto-no-cliente {
        width: 100%;
      }
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Max Width Container
    section {
      width: 480px;
    }

    // Cards Container
    #cards-container {
      width: 100%;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Max Width Container
    section {
      width: 400px;
    }

    // Cards Container
    #cards-container {
      justify-content: flex-start;

      // Texto
      p {
        width: 100%;
      }
    }
  }

  // 525px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile525}) {
    // Max Width Container
    section {
      max-width: 85%;
    }

    // Cards Container
    #cards-container {
      // Formulario
      form {
        // Input Container
        .input-container {
          width: 100%;
          margin: 0px 0px 0px 0px;
        }

        // Input Mail Container
        #input-email-container {
          margin: 16px 0px 0px 0px;
        }
      }
    }
  }
`;

export default Container;
