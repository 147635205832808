import React from "react";
// Estilos
import Container from "./styled";
// Librerías
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { Link } from "react-scroll";

export default function Pasos() {
  return (
    <Container>
      {/* Section Max Width Container */}
      <section id="max-width-container">
        {/* Tag */}
        <p id="tag">Pasos</p>
        {/* Title */}
        <h3>
          ¡Asegurate en solo<span> 3 pasos</span>!
        </h3>
        {/* Pasos Container */}
        <div id="pasos-container">
          {/* Paso 1 */}
          <section>
            {/* Data Container */}
            <div>
              <img
                alt="Escudo de SeguroWeb que marca el progreso de los pasos"
                src="/home/pasos/uno.svg"
              ></img>
              {/* Tag */}
              <p>PASO 1 - ELEGÍ</p>
              {/* Titulo */}
              <h4>
                <strong>Seleccioná el seguro</strong>
                <br></br>
                <span> que necesitas</span>
              </h4>
              {/* Texto */}
              <p>
                Elegí tu seguro desde nuestra web y accedé de forma online al
                detalle de planes, coberturas y costos. Hogar, auto, moto, bici,
                mascota, bolso protegido, ¡y mucho más!.
                <br></br>
                <br></br>
                Contamos con seguros en más de 40 productos 🤩 ¡Seguro tenemos
                lo que estás buscando!.
              </p>
            </div>
            {/* Image */}
            <img className="paso-image" src="/home/pasos/1.webp"></img>
          </section>
          {/* Linea Circulo Container */}
          <div className="linea-circulo-container">
            <span className="linea"></span>
            <span className="circulo"></span>
          </div>
          {/* Paso 2 */}
          <section id="paso-dos-container">
            {/* Image */}
            <img className="paso-image" src="/home/pasos/2.webp"></img>
            {/* Data Container */}
            <div>
              <img
                alt="Escudo de SeguroWeb que marca el progreso de los pasos"
                src="/home/pasos/dos.svg"
              ></img>
              {/* Tag */}
              <p>PASO 2 - COTIZÁ</p>
              {/* Titulo */}
              <h4>
                <strong>Cotizá tu seguro y elegí</strong>
                <br></br> el plan
                <span> ideal para vos</span>
              </h4>
              {/* Texto */}
              <p>
                Tenemos una amplia variedad de coberturas y precios, con las
                compañías más sólidas del mercado.
                <br></br>
                <br></br>
                Conocelas través de nuestros cotizadores online o poniéndote en
                contacto por teléfono, WhatsApp o email, ¡estamos para
                asesorarte de la manera que te sea más conveniente!.
              </p>
            </div>
          </section>
          {/* Linea Circulo Container */}
          <div className="linea-circulo-container">
            <span className="linea"></span>
            <span className="circulo"></span>
          </div>
          {/* Paso 3 */}
          <section id="paso-tres-container">
            {/* Data Container */}
            <div>
              <img
                alt="Escudo de SeguroWeb que marca el progreso de los pasos"
                src="/home/pasos/tres.svg"
              ></img>
              {/* Tag */}
              <p>PASO 3 - CONTACTO</p>
              {/* Titulo */}
              <h4>
                <strong>Te contactamos para</strong>
                <br></br>
                <span> chequear tu contratación</span>
              </h4>
              {/* Texto */}
              <p>
                Te vamos a asesorar y acompañar durante todo el proceso de
                consulta y contratación, para que siempre te sientas seguro y
                elijas la cobertura a tu medida.
              </p>
            </div>
            {/* Image */}
            <img className="paso-image" src="/home/pasos/3.webp"></img>
          </section>
          {/* Linea Circulo Container */}
          <div className="linea-circulo-container">
            <span className="linea"></span>
            <span className="circulo"></span>
          </div>
        </div>
        {/* Asegurado Container*/}
        <div id="asegurado-container">
          <img
            alt="Escudo de SeguroWeb que marca el progreso de los pasos"
            src="/home/pasos/cuatro.svg"
          ></img>
          <h4>
            Y listo, desde ahora
            <br />
            <span>¡ya estas asegurado!</span>
          </h4>
          {/* Texto */}
          <p>
            Como cliente de SeguroWeb, estaremos a tu disposición a través del
            canal de contacto que te sea más cómodo, para cualquier consulta o
            necesidad que tengas. ¡Estamos para servirte!.
          </p>
          <Link
            to="seguros"
            smooth={true}
            spy={true}
            duration={1000}
            offset={-150}
          >
            Cotizá tu seguro ahora
            <span>
              <HiOutlineArrowLongRight />
            </span>
          </Link>
        </div>
      </section>
    </Container>
  );
}
