import styled from "styled-components";
import { breakpoints } from "../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 48px 0px;
  background-color: var(--celeste90);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;

  // Borde Gradiente
  #border-gradient {
    width: 100%;
    height: 6px;
    background: linear-gradient(269deg, #0efec1, #001fff, #0efec1, #001fff);
    background-size: 240% 240%;
    animation: gradiente 8s ease infinite;
    content: "";
  }

  // Animación Borde Gradiente
  @keyframes gradiente {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  // Max Width Container
  #max-width-container {
    width: 1140px;
    padding: 80px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Logo Data Container
  #logo-data-container {
    width: 100%;
    margin: 0px 0px 48px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    // Botones
    a {
      height: 33.14px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
    }

    // Logo
    a img {
      height: 100%;
      transition: 0.2s all;
    }

    // Logo Hover
    a:hover > img {
      transform: scale(0.98);
    }

    // Data Container
    div {
      margin: 0px 0px 0px 0px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      align-content: center;

      // Botones
      a {
        margin: 0px 0px 0px 16px;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 180%;
        text-align: left;
        letter-spacing: -0.015em;
        color: var(--gris);
      }

      // Texto
      p {
        margin: 0px 0px 0px 16px;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 180%;
        text-align: left;
        letter-spacing: -0.015em;
        color: var(--gris);
      }
    }
  }

  // Navs Container
  #navs-container {
    width: 40%;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;

    // Columa
    div {
      width: auto;
      margin: 0px 64px 0px 0px;

      // Titulo
      p {
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 6px 0px;
        font-weight: 500;
        font-size: 13px;
        color: var(--verde50);
      }

      // Li
      nav li {
        height: 30px;
        list-style-type: none;
      }

      // Botones
      nav li a {
        padding: 6px 0px 6px 0px;
        text-decoration: none;
        transition: 0.2s all;
        cursor: pointer;
        color: var(--gris);
        font-size: 13px;
        font-family: DM Sans;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      // Botones Hover
      nav li a:hover {
        color: var(--azul100);
      }
    }
  }

  // Arrepentimiento Baja Container+
  #arrepentimiento-baja-container {
    width: 50%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-start;
    align-items: flex-start;

    // Card
    div {
      width: 280px;
      margin: 0px 0px 0px 0px;
      padding: 14px 14px 10px 14px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      align-items: center;
      border: 2px solid var(--gris90);
      border-radius: 8px;

      // Titulo
      h4 {
        width: 100%;
        margin: 0px 0px 0px 0px;
        font-weight: 500;
        font-size: 15px;
        text-align: left;
        letter-spacing: -0.015em;
        line-height: 143.5%;

        // Span
        span {
          color: var(--azul100);
        }
      }

      // Texto
      p {
        width: auto;
        margin: 6px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 180%;
        text-align: left;
        letter-spacing: -0.015em;
        color: var(--lila90);
        transition: 0.2s all;
      }

      // Botón
      a {
        width: auto;
        margin: 6px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        align-content: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 180%;
        color: var(--verde50);
        transition: 0.2s all;
        cursor: pointer;

        // Span
        span {
          margin: 5px 0px 0px 4px;
          font-size: 20px;
          transition: 0.2s all;
        }
      }

      // Botón Hover
      a:hover {
        color: var(--verde90);

        // Span
        span {
          color: var(--verde90);
          transform: translate(5px, 0px);
        }
      }
    }

    // Second Card
    div:nth-child(2) {
      margin: 24px 0px 0px 0px;
    }
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    // Max Width Container
    #max-width-container {
      width: 1000px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    #max-width-container {
      width: 680px;
    }

    // Logo Data Container
    #logo-data-container {
      flex-wrap: wrap;

      // Data Container
      div {
        width: 100%;
        margin: 32px 0px 0px 0px;
        justify-content: flex-start;

        // Botones
        a {
          margin: 0px 16px 0px 0px;
        }

        // Texto
        p {
          margin: 0px 16px 0px 0px;
        }
      }
    }

    // Navs Container
    #navs-container {
      width: 54%;
    }

    // Arrepentimiento Baja Container
    #arrepentimiento-baja-container {
      width: 46%;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Max Width Container
    #max-width-container {
      width: 480px;
    }

    // Logo Data Container
    #logo-data-container {
      // Data Container
      div {
        width: 70%;
        flex-wrap: wrap;
      }
    }

    // Navs Container
    #navs-container {
      width: 100%;
    }

    // Arrepentimiento Baja Container
    #arrepentimiento-baja-container {
      width: 100%;
      margin: 40px 0px 0px 0px;
      justify-content: flex-start;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Max Width Container
    #max-width-container {
      width: 400px;
    }

    // Arrepentimiento Baja Container+
    #arrepentimiento-baja-container {
      // Card
      div {
        width: 100%;
      }
    }
  }

  // 525px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile525}) {
    // Max Width Container
    #max-width-container {
      max-width: 85%;
    }

    // Arrepentimiento Baja Container+
    #arrepentimiento-baja-container {
      // Card
      div {
        // Texto
        p {
          width: 100%;
        }
      }
    }
  }
`;

export default Container;
