import React from "react";
// Estilos
import Container from "./styled";

export default function Texto() {
  return (
    <Container>
      {/* ¿Quiénes somos? */}
      {/* Título */}
      <h4>
        ¿Quiénes <span>somos</span>?
      </h4>
      {/* Texto */}
      <p>
        SeguroWeb es una marca registrada de la compañía Gestión y Servicios SRL
        Bróker de Seguros multiproductos que se especializa en el asesoramiento
        y venta de Seguros por Internet. La Compañía existe desde 1988 y
        nuestros 28 años de experiencia respaldan nuestro rendimiento en el
        Mercado de Seguros. SeguroWeb comercializa Seguros Online por medio de
        un multicotizador que permite comparar compañías y coberturas.
      </p>
      {/* Nuestros valores */}
      {/* Título */}
      <h4>
        Nuestros <span>valores</span>
      </h4>
      {/* Texto */}
      <p>
        Mantener al Cliente satisfecho a través de la mejor Calidad de Servicio,
        en Cotización y Contratación Online de Seguros, Gestión de Siniestros y
        Atención Personalizada. ¿Cómo lo hacemos? Contamos con la experiencia y
        la eficiencia como pilares fundamentales, conjuntamente con un
        importante avance tecnológico, lo que avala la propuesta para que usted
        y sus clientes puedan sentirse plenamente respaldados.
      </p>
      {/* Visión comercial */}
      {/* Título */}
      <h4>
        Visión <span>comercial</span>
      </h4>
      {/* Texto */}
      <p>
        Vivimos en un mundo en permanente cambio y SeguroWeb es la demostración
        de esto para Gestión y Servicios SRL. En un mercado tradicional como lo
        es el asegurador, el avance de la tecnología y las modificaciones del
        comportamiento de los consumidores han posibilitado la llegada de la
        Venta de Seguros en plataformas Web. SeguroWeb, pionero en la Venta de
        Seguros por Internet, se posiciona hoy como líder de este canal.
        Permitiendo constantemente la realización de nuevos Seguros.
      </p>
      {/* Visión comercial */}
      {/* Título */}
      <h4>
        ¿Qué <span>hemos logrado</span>?
      </h4>
      {/* Texto */}
      <p>
        Contamos con más de 32.000 pólizas vigentes. Trabajamos con 23 Compañías
        de Seguros, incluyendo a las más importantes del país como Federación
        Patronal, ATM, Libra Seguros, Profru Seguros y Mapfre, entre muchísimas
        otras. Hemos logrado desarrollar una gran comunidad por medio de
        nuestras redes sociales, como así también por nuestro blog y campañas de
        email marketing con más de 40.000 participantes.
      </p>
    </Container>
  );
}
